import { combineReducers } from "redux";
import MainSustainabilityReducer, { MainSustainabilityState } from "./main";
import FilesReducer, { FilesState } from "./files";
import AllocationReducer, { AllocationState } from "./allocation";
import CustomerReducer, { CustomerState } from "./customer";
import ReportsReducer, { ReportsState } from "./reports";
import RoaduseAllocationReducer, {RoaduseAllocationState} from "./allocation/roaduseAllocation";
import OffRoadAllocationReducer, {OffRoadAllocationState} from "./allocation/offRoadAllocation";
import MarineAllocationReducer, {MarineAllocationState} from "./allocation/marineAllocation";

export interface SustainabilityState {
  main: MainSustainabilityState;
  files: FilesState;
  allocation: AllocationState;
  roaduseAllocation: RoaduseAllocationState;
  offRoadAllocation: OffRoadAllocationState;
  marineAllocation: MarineAllocationState;
  customer: CustomerState;
  reports: ReportsState;
}


export default combineReducers({
  // @ts-ignore
  main: MainSustainabilityReducer,
  // @ts-ignore
  files: FilesReducer,
  // @ts-ignore
  allocation: AllocationReducer,
  // @ts-ignore
  roaduseAllocation: RoaduseAllocationReducer,
  // @ts-ignore
  offRoadAllocation: OffRoadAllocationReducer,
  // @ts-ignore
  marineAllocation: MarineAllocationReducer,
  // @ts-ignore
  customer: CustomerReducer,
  // @ts-ignore
  reports: ReportsReducer,
});
