export default (data: string | number | null | undefined, decimalPlaces: number | null = null) => {
  if (data == null) {
    return NaN;
  }
  const parsed = data.toString().replace(/[^0-9.,-]/g, "").replace(/,/g, ".").replace(/(?!^)-/g, "");
  const num = parseFloat(parsed);

  if (decimalPlaces !== null) {
    if (isNaN(num)) {
      return 0;
    }
    return parseFloat(num.toFixed(decimalPlaces));
  }
  return num;
};
