export default (data: string | number, decimals?: number, isEdit = false) => {
  // if (!data) return "";
  let parsed = data;
  let decimalString = "";
  if (data === null || data === undefined) { return ""; }
  if (Number(parsed) || Number(parsed) === 0) {
    if (!isEdit && decimals) {
      decimalString = ",".concat(
        parsed.toString().split(".")[1]
          ? parsed.toString().split(".")[1].concat("000").substr(0, decimals)
          : "".concat("000").substr(0, decimals)
      );
    } // save decimals
    if (isEdit && parsed.toString().includes(".")) {
      if (decimals === null) {
        decimalString = ",".concat(parsed.toString().split(".")[1]);
      }
      // @ts-ignore
      if (decimals != null && decimals > 0) {
          const fractionLength = parsed.toString().split(".")[1].length;
          decimalString = ",".concat(
              parsed.toString().split(".")[1].substr(0, Math.min(decimals, fractionLength))
          );
      }
    }
    parsed = parsed.toString().split(".")[0]; // remove decimals
    parsed = parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  parsed = parsed.toString().concat(decimalString);
  if (!isEdit && parsed.includes(",")) {
    const parts = parsed.split(",");
    if (/^0+$/.test(parts[1])) {
      parsed = parts[0];
    }
  }
  return parsed;
};
