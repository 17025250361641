import React, {Props, useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ButtonRounded from "../../../components/ButtonRounded";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import {useHistory} from "react-router-dom";
import { getOffRoadUseData, getOffRoadUseCreateData, getOffRoadUseEditData } from "../../../api/sustainability/allocation/offRoadUse/data";
import excise_duty_rates from "../../../views/DashboardView/SettingsView/SettingProperties/excise_duty_rates";
import clsx from "clsx";
import parseAndFormat from "../../../components/texts/parseAndFormat";

interface DieselVol {
    diesel: any;
    biocomponent_in_diesel: any;
    fame: any;
    fame_advanced: any;
    hvo_hro: any;
    hvo_hro_advanced: any;
}

interface DieselBioAboveMandate {
    fame: any;
    fame_advanced: any;
    hvo_hro: any;
    hvo_hro_advanced: any;
}

interface Mandate {
    name: any;
    level: any;
    required_volume: any;
    relevant_volume: any;
}

interface MandateType {
    general_mandate: any;
}

interface DieselExciseDuty {
    diesel: any;
    bio_components_in_diesel: any;
}

interface preli_surplus {
    additional_surplus: any;
    surplus_from_roaduse: any;
}

interface ParamsType {
    id?: any;
    mandate: string,
    diesel_vol_card: {
        sold_vol: {
            diesel: any,
            fame: any,
            fame_advanced: any,
            hvo_hro: any,
            hvo_hro_advanced: any
        },
        bio_above_mandate: {
            fame: any,
            fame_advanced: any,
            hvo_hro: any,
            hvo_hro_advanced: any
        },
        bio_in_fulfillment: {
            "biocomponent_in_diesel": any,
            "fame": any,
            "fame_advanced": any,
            "hvo_hro": any,
            "hvo_hro_advanced": any
        }
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr", // Two columns with equal width
            alignItems: "center",
            gap: theme.spacing(2), // Add some gap between columns
        },
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
            padding: "10px",
        },
        buttonContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: theme.spacing(2),
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
        },

        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px 0",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        cardAllocation: {
            flex: "1", // Takes 2/3 of the available space
        },

        // Style for the second card with 1/3 space
        cardReport: {
            flex: "2", // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "12px",
                fontWeight: 700,
                backgroundColor: theme.palette.grey[300],
            },
        },
        tableContainer: {
            maxHeight: "450px",
            overflowY: "auto", // "scroll",
            "& .MuiOutlinedInput-input": {
                ...theme.typography.caption,
                fontWeight: 500,
                color: theme.palette.primary.main,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        noPadding: {
            padding: 0,
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
        bgGreen: {
            backgroundColor: "#cce6d8",
        },
        bgRed: {
            backgroundColor: "#ffdad1",
        },
        green: {
            backgroundColor: "#cce6d8",
        },
        red: {
            backgroundColor: "#ffdad1",
        },
        yellow: {
            backgroundColor: "#ffd588",
        },
    })
);

const OffRoadVolumeContainer: React.FC<Props<any>> = () => {
    const classes = useStyles();
    const history = useHistory();
    const [roadUseId, setRoadUseId] = useState<any>(null);
    const [mandate, setMandate] = useState<MandateType>({
        general_mandate: {}
    });
    const [generalMandate, setGeneralMandate] = useState<Mandate>({
        name: "General Mandate",
        level: 0,
        required_volume: 0,
        relevant_volume: 0
    });

    const [dieselExciseDuty, setDieselExciseDuty] = useState<DieselExciseDuty>({
        diesel: 0,
        bio_components_in_diesel: 0
    });

    const [preliSurplus, setPreliSurplus] = useState<preli_surplus>({
    additional_surplus: 0,
    surplus_from_roaduse: 0
    });

    const [preBioGasoline, setPreBioGasoline] = useState<number>(0);
    const [preBioGasolineAdditional, setPreBioGasolineAdditional] = useState<number>(0);
    const [preBioDiesel, setPreBioDiesel] = useState<number>(0);
    const [preBioDieselAdditional, setPreBioDieselAdditional] = useState<number>(0);
    const [numericValue, setNumericValue] = useState<number>(0);
    const [rawInputs, setRawInputs] = useState<{ [key: string]: string }>({});
    const [rawBioAboveInputs, setRawBioAboveInputs] = useState<{ [key: string]: string }>({});

    const [dieselVol, setDieselVol] = useState<DieselVol>({
        diesel: 0,
        biocomponent_in_diesel: 0,
        fame: 0,
        fame_advanced: 0,
        hvo_hro: 0,
        hvo_hro_advanced: 0
    });

    const [dieselBioAboveMandate, setDieselBioAboveMandate] = useState<DieselBioAboveMandate>({
        fame: 0,
        fame_advanced: 0,
        hvo_hro: 0,
        hvo_hro_advanced: 0
    });

    const handleDieselVol = (fieldName: string) => (e: any) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");

        // Check if the input is a valid number with two decimal places
        if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
            const formattedValue = parseAndFormat(inputValue, 2, true);

            setRawInputs(prevRawInputs => ({
                ...prevRawInputs,
                [fieldName]: formattedValue,
            }));

            setDieselVol(prevValues => ({
                ...prevValues,
                [fieldName]: inputValue
            }));
        }

        const biocomponentDieselVol = parseFloat(dieselVol.fame)
            + parseFloat(dieselVol.fame_advanced)
            + parseFloat(dieselVol.hvo_hro)
            + parseFloat(dieselVol.hvo_hro_advanced);

        setDieselVol(prevValues => ({
            ...prevValues,
            ["biocomponent_in_diesel"]: biocomponentDieselVol
        }));
    };

    const handleDieselBioAboveMandate = (fieldName: string) => (e: any) => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");

        // Check if the input is a valid number with two decimal places
        if (/^\d+(\.\d{0,2})?$/.test(inputValue) || inputValue === "") {
            const formattedValue = parseAndFormat(inputValue, 2, true);

            setRawBioAboveInputs(prevRawBioAboveInputs => ({
                ...prevRawBioAboveInputs,
                [fieldName]: formattedValue,
            }));

            setDieselBioAboveMandate(prevValues => ({
                ...prevValues,
                [fieldName]: inputValue
            }));
        }
    };

    const fetchData = async () => {
        const response = await getOffRoadUseData("off-road");
        if (response && !response.error) {
            if (response.data.id) {
                setRoadUseId(response.data.id);
            }

            if (response.data.preli_mandate_fulfill) {
                setMandate(response.data.preli_mandate_fulfill.mandate);
            }

            if (response.data.preli_calc_surplus_vol) {
                setPreliSurplus(response.data.preli_calc_surplus_vol);
            }

            if (response.data.diesel_sold_vol) {
                setDieselVol(response.data.diesel_sold_vol);
            }
            if (response.data.diesel_bio_above_mandate) {
                setDieselBioAboveMandate(response.data.diesel_bio_above_mandate);
            }

            if (response.data.diesel_excise_duty) {
                setDieselExciseDuty(response.data.diesel_excise_duty);
            }
        } else {
            alert("Some error occured");
        }
    };

    const handleSave = async () => {
        const params: ParamsType = {
            mandate: "offroad",
            diesel_vol_card: {
                sold_vol: {
                    diesel: dieselVol.diesel,
                    fame: dieselVol.fame,
                    fame_advanced: dieselVol.fame_advanced,
                    hvo_hro: dieselVol.hvo_hro,
                    hvo_hro_advanced: dieselVol.hvo_hro_advanced
                },
                bio_above_mandate: {
                    fame: dieselBioAboveMandate.fame,
                    fame_advanced: dieselBioAboveMandate.fame_advanced,
                    hvo_hro: dieselBioAboveMandate.hvo_hro,
                    hvo_hro_advanced: dieselBioAboveMandate.hvo_hro_advanced
                },
                bio_in_fulfillment: {
                    biocomponent_in_diesel: (
                            parseFloat(dieselVol.fame)
                            + parseFloat(dieselVol.fame_advanced)
                            + parseFloat(dieselVol.hvo_hro)
                            + parseFloat(dieselVol.hvo_hro_advanced)
                        ) -
                        (
                            parseFloat(dieselBioAboveMandate.fame)
                            + parseFloat(dieselBioAboveMandate.fame_advanced)
                            + parseFloat(dieselBioAboveMandate.hvo_hro)
                            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                        ),
                    fame: parseFloat(dieselVol.fame) - parseFloat(dieselBioAboveMandate.fame),
                    fame_advanced: parseFloat(dieselVol.fame_advanced) - parseFloat(dieselBioAboveMandate.fame_advanced),
                    hvo_hro: parseFloat(dieselVol.hvo_hro) - parseFloat(dieselBioAboveMandate.hvo_hro),
                    hvo_hro_advanced: parseFloat(dieselVol.hvo_hro_advanced) - parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                }
            }
        };

        if (roadUseId) {
            params.id = roadUseId;
            const response = await getOffRoadUseEditData(params);
            if (response && !response.error) {
                alert("Data Updated");
            } else {
                alert("Some error occured");
            }
        } else {
            const response2 = await getOffRoadUseCreateData(params);
            if (response2 && !response2.error) {
                alert("Data Updated");
            } else {
                alert("Some error occured");
            }
        }

        return true;

    };

    const generalMandateReqVol = (( parseFloat(dieselVol.fame)
        + parseFloat(dieselVol.fame_advanced)
        + parseFloat(dieselVol.hvo_hro)
        + parseFloat(dieselVol.hvo_hro_advanced)
        + parseFloat(dieselVol.diesel)
    ) * (parseFloat(mandate.general_mandate.level) / 100)).toFixed(2);

    const generalMandateRelVol = (
        (
          
             parseFloat(dieselVol.fame_advanced)
         
            + parseFloat(dieselVol.hvo_hro_advanced)
        ) -
        (
            parseFloat(dieselBioAboveMandate.fame)
            + parseFloat(dieselBioAboveMandate.fame_advanced)
            + parseFloat(dieselBioAboveMandate.hvo_hro)
            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
        ) +
            (
                parseFloat(preliSurplus.surplus_from_roaduse)
            )
    ).toFixed(2);

    const biocomponentDiesel = (
        (
        parseFloat(dieselVol.fame)
        + parseFloat(dieselVol.fame_advanced)
        + parseFloat(dieselVol.hvo_hro)
        + parseFloat(dieselVol.hvo_hro_advanced)
        ) -
        (
            parseFloat(dieselBioAboveMandate.fame)
            + parseFloat(dieselBioAboveMandate.fame_advanced)
            + parseFloat(dieselBioAboveMandate.hvo_hro)
            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
        )
    ).toFixed(2);

    const generalMandateStatus = () => {
        const I9: number = parseFloat(generalMandateReqVol);
        const K9: number = parseFloat(generalMandateRelVol);
        const O24: number = parseFloat(biocomponentDiesel);

        // @ts-ignore
        if (I9 === 0) {
            return "No off-road mandate to fulfill";
        } else if (I9 <= K9) {
            if (O24 < K9) {
                return "OK (mandate fulfilled, incl. surplus from road use)";
            } else {
                return "OK (mandate fulfilled)";
            }
        } else {
            return "NB! Mandate will not be fulfilled";
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        generalMandateStatus();
    }, [generalMandateReqVol, generalMandateRelVol, biocomponentDiesel]);

    useEffect(() => {
        setGeneralMandate(mandate.general_mandate);
    }, [mandate]);

    return (
        <div>
            <div className={classes.container}>
                <div>
                    <h1>Off Road use volumes</h1>
                </div>
                <div className={classes.buttonContainer}>
                    <ButtonRounded className={classes.buttonMargin}
                                   fullWidth
                                   variant="contained"
                                   color="secondary"
                                   onClick={() => history.goBack()}// Add your click handler
                    >
                        Cancel
                    </ButtonRounded>
                    <ButtonRounded className={classes.buttonMargin}
                                   fullWidth
                                   variant="contained"
                                   color="primary"
                                   onClick={() => handleSave()} // Add your click handler
                    >
                        Save
                    </ButtonRounded>
                </div>
            </div>

            <div>
                <h3>Preliminary mandate fulfillment</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead className="MuiTableCell-head">
                            <TableRow>
                                <TableCell>Mandate Description</TableCell>
                                <TableCell>Mandate Level</TableCell>
                                <TableCell>Required Volume</TableCell>
                                <TableCell>Relevant Volume</TableCell>
                                <TableCell>Estimated Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="MuiTableBody-root">
                            <TableRow>
                                <TableCell style={{width: "20%"}}>{ mandate.general_mandate.name }</TableCell>
                                <TableCell style={{width: "20%"}}>{ mandate.general_mandate.level }%</TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(generalMandateReqVol, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(generalMandateRelVol, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}} className={clsx({
                                    [classes.green]: generalMandateStatus() === "OK (mandate fulfilled, incl. surplus from road use)" || generalMandateStatus() === "OK (mandate fulfilled)",
                                    [classes.red]: generalMandateStatus() === "NB! Mandate will not be fulfilled" || generalMandateStatus() === "No off-road mandate to fulfill",
                                })}>
                                    {generalMandateStatus()}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Preliminary calculations of surplus volume</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Surplus Description</TableCell>
                                <TableCell>Required by customer</TableCell>
                                <TableCell>Est. Additional surplus</TableCell>
                                <TableCell>Est. Surplus to off-road</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>Bio component in diesel</TableCell>
                                <TableCell style={{width: "25%"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselBioAboveMandate.fame)
                                        + parseFloat(dieselBioAboveMandate.fame_advanced)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preliSurplus.additional_surplus, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preliSurplus.surplus_from_roaduse, 2) }</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>Total surplus</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioGasoline + preBioDiesel, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}>{ parseAndFormat(preBioGasolineAdditional + preBioDieselAdditional, 2) }</TableCell>
                                <TableCell style={{width: "25%"}}></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>

            <div>
                <h3>Diesel Volumes</h3>
                <Card className={classes.card}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product Component</TableCell>
                                <TableCell>Excise duty volume</TableCell>
                                <TableCell>Sold Volume</TableCell>
                                <TableCell>Bio above mandate</TableCell>
                                <TableCell>Bio to be used in fulf.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Diesel</TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {parseAndFormat(dieselExciseDuty.diesel, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="diesel"
                                        value={rawInputs["diesel"] || parseAndFormat(dieselVol.diesel, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("diesel")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                                <TableCell style={{width: "20%"}}>n/a</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    Bio component in Diesel
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {parseAndFormat(dieselExciseDuty.bio_components_in_diesel, 2)}
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.fame)
                                        + parseFloat(dieselVol.fame_advanced)
                                        + parseFloat(dieselVol.hvo_hro)
                                        + parseFloat(dieselVol.hvo_hro_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(parseFloat(dieselBioAboveMandate.fame)
                                        + parseFloat(dieselBioAboveMandate.fame_advanced)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro)
                                        + parseFloat(dieselBioAboveMandate.hvo_hro_advanced), 2)
                                    }
                                </TableCell>
                                <TableCell style={{width: "20%", borderBottom: "1px solid black"}}>
                                    {
                                        parseAndFormat(
                                        (
                                             parseFloat(dieselVol.fame_advanced)
                                            + parseFloat(dieselVol.hvo_hro_advanced)
                                        ) -
                                        (
                                             parseFloat(dieselBioAboveMandate.fame_advanced)
                                            + parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                                        ), 2)

                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Fame</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="fame"
                                        value={rawInputs["fame"] || parseAndFormat(dieselVol.fame, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("fame")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_fame"
                                        value={rawBioAboveInputs["fame"] || parseAndFormat(dieselBioAboveMandate.fame, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("fame")}
                                        fullWidth
                                        margin="normal"
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    n/a
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>Fame (advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="fame_advanced"
                                        value={rawInputs["fame_advanced"] || parseAndFormat(dieselVol.fame_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("fame_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        name="above_fame_advanced"
                                        value={rawBioAboveInputs["fame_advanced"] || parseAndFormat(dieselBioAboveMandate.fame_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("fame_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(
                                            parseFloat(dieselVol.fame_advanced)
                                            - parseFloat(dieselBioAboveMandate.fame_advanced)
                                            , 2
                                        )
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>HVO/HRO</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawInputs["hvo_hro"] || parseAndFormat(dieselVol.hvo_hro, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("hvo_hro")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawBioAboveInputs["hvo_hro"] || parseAndFormat(dieselBioAboveMandate.hvo_hro, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("hvo_hro")}
                                        fullWidth
                                        margin="normal"
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    n/a
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: "20%"}}>HVO/HRO(advanced)</TableCell>
                                <TableCell style={{width: "20%"}}></TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawInputs["hvo_hro_advanced"] || parseAndFormat(dieselVol.hvo_hro_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselVol("hvo_hro_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    <TextField
                                        label=""
                                        type="text"
                                        value={rawBioAboveInputs["hvo_hro_advanced"] || parseAndFormat(dieselBioAboveMandate.hvo_hro_advanced, 2)}
                                        variant="outlined"
                                        onChange={handleDieselBioAboveMandate("hvo_hro_advanced")}
                                        fullWidth
                                        margin="normal"
                                    />
                                </TableCell>
                                <TableCell style={{width: "20%"}}>
                                    {
                                        parseAndFormat(
                                            parseFloat(dieselVol.hvo_hro_advanced)
                                            - parseFloat(dieselBioAboveMandate.hvo_hro_advanced)
                                            , 2
                                        )
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableHead>
                            <TableRow>
                                <TableCell>Total Sold Diesel Volumes</TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    {
                                        parseAndFormat(parseFloat(dieselVol.fame)
                                        + parseFloat(dieselVol.fame_advanced)
                                        + parseFloat(dieselVol.hvo_hro)
                                        + parseFloat(dieselVol.hvo_hro_advanced)
                                        + parseFloat(dieselVol.diesel), 2)
                                    }
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Card>
            </div>
        </div>
    );
};

export default OffRoadVolumeContainer;
