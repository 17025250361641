import React, {useContext, useEffect, useState} from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import parseData from "../../../../components/texts/parseData";

import SmartTable from "../../../../components/SmartTable/allocation";
import OverviewTableContainer from "./OverviewTable";
import OverviewPanel
  from "../../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OverviewPanel";
import {Typography} from "@material-ui/core";
import {getStepData} from "../../../../api/sustainability/allocation/offRoadUse/stepData";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import PrevTable from "./PrevTable";
import parseAndFormat from "../../../../components/texts/parseAndFormat";
import parseToFloat from "../../../../components/texts/parseToFloat";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      overflowY: "auto",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
      },
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        ...theme.typography.caption,
        fontWeight: 500,
      },
    },
  })
);

const tableHeadings = [
  { name: "Storage Date", id: "storage_date"},
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "raw_material_origin" },
  { name: "Biocomponent Type", id: "fuel_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "double_counted_norway" },
  { name: "Ghg Reduction", id: "reduction_in_climate_emission" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  // {
  //   name: "Allocated Quantity (LE)",
  //   alignRight: true,
  //   id: "allocated_eq_quantity",
  // },
];

const prevTableHeadings = [
  { name: "Biofuel Type", id: "biofuel_type" },
  { name: "Origin", id: "origin" },
  { name: "Biocomponent Type", id: "biocomponent_type" },
  { name: "Raw Material", id: "raw_material" },
  { name: "Advanced", id: "advanced" },
  { name: "Ghg Reduction", id: "ghg_reduction" },
  { name: "Storage Terminal", id: "storage_terminal" },
  {
    name: "Available Quantity (L)",
    alignRight: true,
    id: "available_quantity",
  },
  {
    name: "Allocated Quantity (L)",
    alignRight: true,
    id: "allocated_quantity",
  },
  // {
  //   name: "Allocated Quantity (LE)",
  //   alignRight: true,
  //   id: "allocated_eq_quantity",
  // },
];

interface UpdatedDataProps {
  biotemplates?: any[];
  overview?: any[];
  id?: number;
}

interface Props {
  data: any[];
  activeStep: number;
  onUpdateData: (updatedData: UpdatedDataProps, type: string) => void;
  onUpdateRequirement: (requirementData: Requirement) => void;
  updateIsFetching: (value: boolean) => void;
}

interface OverViewReqParamProps {
  general_mandate: number;
  general_req_vol: number;
  general_rel_vol: number;
  above_mandate_req_by_cust: number;
  fame_advanced: number;
  fame: number;
  hvo_hro_advanced: number;
  hvo_hro: number;
  above_mandate_after_fulfill: number;
  vol_not_applicable_to_mandate: number;
}
interface OverViewParamProps {
  general_mandate: number;
  general_req_vol: number;
  general_rel_vol: number;
  above_mandate_req_by_cust: number;
  fame_advanced: number;
  fame: number;
  hvo_hro_advanced: number;
  hvo_hro: number;
  above_mandate_after_fulfill: number;
  vol_not_applicable_to_mandate: number;
}
interface OverViewProps {
  req_vol: OverViewReqParamProps;
  joint_fulfill: OverViewParamProps;
  allocated_LE: OverViewParamProps;
  allocated_15: OverViewParamProps;
  offroad_surplus: OverViewParamProps;
  remaining_vol: OverViewParamProps;
}
interface Requirement {
  construction_volume_requirement: any;
}

const EditableTable: React.FC<Props> = ({
                                          data,
                                          activeStep,
                                          onUpdateData,
                                          onUpdateRequirement,
                                          updateIsFetching,
                                        }) => {
  const classes = useStyles();
  const [overViewData, setOverViewData] = useState<OverViewProps>({
    req_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    joint_fulfill: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    allocated_LE: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    allocated_15: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    offroad_surplus: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    remaining_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    }
  });

  const [responseOverViewData, setResponseOverViewData] = useState<OverViewProps>({
    req_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    joint_fulfill: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    allocated_LE: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    allocated_15: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    offroad_surplus: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    },
    remaining_vol: {
      general_mandate: 0,
      general_req_vol: 0,
      general_rel_vol: 0,
      above_mandate_req_by_cust: 0,
      fame_advanced: 0,
      fame: 0,
      hvo_hro_advanced: 0,
      hvo_hro: 0,
      above_mandate_after_fulfill: 0,
      vol_not_applicable_to_mandate: 0
    }
  });
  const [newData, setNewData] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [stepDataId, setStepDataId] = useState<number|null>(null);
  const [stepTwosData, setStepTwosData] = useState([]);
  const [stepThreesData, setStepThreesData] = useState([]);
  const [stepFoursData, setStepFoursData] = useState([]);
  const [stepFivesData, setStepFivesData] = useState([]);
  const [stepSixsData, setStepSixsData] = useState([]);
  const [biotemplatesData, setBiotemplatesData] = useState([]);
  const dispatch = useDispatch();
  const updateReqVol = (newValues: Partial<OverViewReqParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      req_vol: { ...prevData.req_vol, ...newValues },
    }));
  };
  const offroadAllocatedQuantityByStep = useContext(ReactReduxContext).store.getState().sustainability.offRoadAllocation;
  // console.log("QS")
  // console.log(offroadAllocatedQuantityByStep)

  const updateJointFulfillVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      joint_fulfill: { ...prevData.joint_fulfill, ...newValues },
    }));
  };

  const updateAllocatedLEVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      allocated_LE: { ...prevData.allocated_LE, ...newValues },
    }));
  };

  const updateAllocated15Vol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      allocated_15: { ...prevData.allocated_15, ...newValues },
    }));
  };

  const updateSurplusFromOffroadVol = (newValues: Partial<OverViewParamProps>) => {
    setOverViewData(prevData => ({
      ...prevData,
      offroad_surplus: { ...prevData.offroad_surplus, ...newValues },
    }));
  };

  const removeUnavailableBiotemplates = (biotemplates: any[]) => {
    // @ts-ignore
    const updatedBiotemplates = biotemplates.filter(item => item.available_quantity >= 1);
    return updatedBiotemplates;
  };

  const getAllocated = (row: any) => parseAndFormat(row.allocated_quantity, 2);

  const stepOne = offroadAllocatedQuantityByStep.biotemplatesInAllocationStepOne;
  const stepTwo = offroadAllocatedQuantityByStep.biotemplatesInAllocationStepTwo;
  const stepThree = offroadAllocatedQuantityByStep.biotemplatesInAllocationStepThree;
  const stepFour = offroadAllocatedQuantityByStep.biotemplatesInAllocationStepFour;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const { value } = event.target;
    const prevData = { ...row };
    const inputValue = value.replace(/[^0-9.,]/g, "").replace(/,/g, ".");
    const newData = { ...row, allocated_quantity: parseAndFormat(inputValue, 0, true) };
    // Assuming you are updating some state here
    setNewData(prevRows => {
      const updatedRowsCopy = [...prevRows];
      // @ts-ignore
      const rowIndex = updatedRowsCopy.findIndex(r => r.id === row.id);

      if (rowIndex !== -1) {
        // @ts-ignore
        updatedRowsCopy[rowIndex] = newData;
      }

      return updatedRowsCopy;
    });
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>, row: any[]) => {
    handleOnChange(event, row);
  };

  const isDoubleCount = (row: any) => {
    return row.double_counted_norway === "Ja";
  };

  const isDiesel = (row: any) => {
    return row.fuel_type === "Biokomponenter for diesel";
  };

  function isNonEmptyArrayOrObject(value: any): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
    }

    return false;
  }

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) { parsed *= -1; }
      return parseData(parsed, 2);
    } else { return parsed; }
  };

  const getData = (data: any[], activeStep: number) => {
    return data;
  };

  const fetchBiotemplateData = async (activeStep: number, currentStepData: any[]) => {
    const response = await getStepData(activeStep);
    if (response) {
      if ("data" in response) {
        setStepData(response.data);
        if ("id" in response.data) {
          setStepDataId(response.data.id);
        }

        if ("req_vol" in response.data) {
          updateReqVol(response.data.req_vol);
          setResponseOverViewData(prevData => ({
            ...prevData,
            req_vol: { ...prevData.req_vol, ...response.data.req_vol },
          }));
        }
        if ("joint_fulfill" in response.data) {
          updateJointFulfillVol(response.data.joint_fulfill);
          setResponseOverViewData(prevData => ({
            ...prevData,
            joint_fulfill: { ...prevData.joint_fulfill, ...response.data.joint_fulfill },
          }));
        }
        if ("allocated_LE" in response.data) {
          updateAllocatedLEVol(response.data.allocated_LE);
          setResponseOverViewData(prevData => ({
            ...prevData,
            allocated_LE: { ...prevData.allocated_LE, ...response.data.allocated_LE },
          }));
        }
        if ("allocated_15" in response.data) {
          updateAllocated15Vol(response.data.allocated_15);
          setResponseOverViewData(prevData => ({
            ...prevData,
            allocated_15: { ...prevData.allocated_15, ...response.data.allocated_15 },
          }));
        }
        if ("offroad_surplus" in response.data) {
          updateSurplusFromOffroadVol(response.data.offroad_surplus);
          setResponseOverViewData(prevData => ({
            ...prevData,
            offroad_surplus: { ...prevData.offroad_surplus, ...response.data.offroad_surplus },
          }));
        }

        if ( "table_from_step_two" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_two)) {
          if (Array.isArray(response.data.table_from_step_two)) {
            setStepTwosData(response.data.table_from_step_two);
          } else {
            setStepTwosData(Object.values(response.data.table_from_step_two));
          }
        }

        if ( "table_from_step_three" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_three)) {
          if (Array.isArray(response.data.table_from_step_three)) {
            setStepThreesData(response.data.table_from_step_three);
          } else {
            setStepThreesData(Object.values(response.data.table_from_step_three));
          }
        }

        if ( "table_from_step_four" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_four)) {
          if (Array.isArray(response.data.table_from_step_four)) {
            setStepFoursData(response.data.table_from_step_four);
          } else {
            setStepFoursData(Object.values(response.data.table_from_step_four));
          }
        }

        if ( "table_from_step_five" in response.data && isNonEmptyArrayOrObject(response.data.table_from_step_five)) {
          if (Array.isArray(response.data.table_from_step_five)) {
            setStepFivesData(response.data.table_from_step_five);
          } else {
            setStepFivesData(Object.values(response.data.table_from_step_five));
          }
        }

        let mappedData;

        if (activeStep === 1 && "table_from_step_two" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_two);
        } else if (activeStep === 2 && "table_from_step_three" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_three);
        } else if (activeStep === 3 && "table_from_step_four" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_four);
        } else if (activeStep === 4 && "table_from_step_five" in response.data) {
          // @ts-ignore
          mappedData = getMappedData(currentStepData, response.data.table_from_step_five);
        } else {
          mappedData = currentStepData;
        }
        const calculatedData = await calculateMappedData(activeStep, mappedData);

        // @ts-ignore
        setNewData(calculatedData);
      } else {
        setStepData([]);
      }
    } else {
      alert("Some error occured");
    }
  };

  const calculateMappedData = async (activeStep: number, data: any[]) => {
    let newCalculatedData = data;

    // Define the steps and the corresponding allocation data
    const steps = [
      offroadAllocatedQuantityByStep.biotemplatesInAllocationStepOne,
      offroadAllocatedQuantityByStep.biotemplatesInAllocationStepTwo,
      offroadAllocatedQuantityByStep.biotemplatesInAllocationStepThree,
      offroadAllocatedQuantityByStep.biotemplatesInAllocationStepFour,
      offroadAllocatedQuantityByStep.biotemplatesInAllocationStepFive
    ];

    // Filter out the current active step
    const filteredSteps = steps.filter((_, index) => index !== activeStep - 1);

    // Apply the balance operation to each filtered step
    for (const stepData of filteredSteps) {
      if (stepData) {
        newCalculatedData = await getBalancedData(newCalculatedData, stepData);
      }
    }

    // Remove items with available_quantity < 1
    newCalculatedData = newCalculatedData.filter(item => item.available_quantity >= 1);

    console.log("activeStep:", activeStep);
    console.log("newCalculatedData:", newCalculatedData);

    return newCalculatedData;
  };

  const getBalancedData = async (existingData: any[], apiData: any[]) => {
    const updatedData = existingData.map(item => {
      // Find matching entry in the API data
      const matchingApiEntry = Object.values(apiData).find(apiItem =>
          apiItem.id === item.id
      );

      if (matchingApiEntry) {
        const itemCopy = { ...item };

        // Subtract the allocated quantity from the available quantity
        itemCopy.available_quantity -= parseToFloat(matchingApiEntry.allocated_quantity);

        return itemCopy;
      }

      return item;
    });

    return updatedData;
  };

  const getMappedData = (existingData: any[], apiData: any[]) => {
    const updatedData = existingData.map(item => {
      // @ts-ignore
      const matchingApiEntry = Object.values(apiData).find(apiItem => apiItem.biotemplate_id === String(item.id)
      );

      if (matchingApiEntry) {
        // Update the allocated_quantity
        // @ts-ignore
        return { ...item, allocated_quantity: matchingApiEntry.allocated_quantity };
      }

      return item;
    });

    return updatedData;
    // return existingData;
  };

  const updateDataTotals = (responseOverViewData: OverViewProps, newData: any[]) => {

    const dataTotals: OverViewProps = JSON.parse(JSON.stringify(responseOverViewData));

    let allocated_15_general_sub_mandate = activeStep < 2 ? 0 : parseToFloat(dataTotals.allocated_15.general_mandate.toString());

    const above_mandate_req_by_cust =  activeStep < 2 ? 0 : parseToFloat(dataTotals.req_vol.above_mandate_req_by_cust.toString());
    let allocated_15_above_mandate_req_by_cust = activeStep < 3 ? 0 : parseToFloat(dataTotals.allocated_15.above_mandate_req_by_cust.toString());
    let allocated_LE_above_mandate_req_by_cust = activeStep < 3 ? 0 : parseToFloat(dataTotals.allocated_LE.above_mandate_req_by_cust.toString());

    const above_mandate_after_fulfill_req_vol = activeStep < 3 ? 0 : parseToFloat(dataTotals.req_vol.above_mandate_after_fulfill.toString());
    let allocated_15_above_mandate_after_fulfill = activeStep < 4 ? 0 : parseToFloat(dataTotals.allocated_15.above_mandate_req_by_cust.toString());
    let allocated_LE_above_mandate_after_fulfill = activeStep < 4 ? 0 : parseToFloat(dataTotals.allocated_LE.above_mandate_req_by_cust.toString());

    const vol_not_applicable_to_mandate = activeStep < 4 ? 0 : parseToFloat(dataTotals.req_vol.vol_not_applicable_to_mandate.toString());
    let allocated_15_vol_not_applicable_to_mandate = activeStep < 5 ? 0 : parseToFloat(dataTotals.allocated_15.vol_not_applicable_to_mandate.toString());
    let allocated_LE_vol_not_applicable_to_mandate = activeStep < 5 ? 0 : parseToFloat(dataTotals.allocated_LE.vol_not_applicable_to_mandate.toString());

    const fame = parseToFloat(dataTotals.req_vol.fame.toString());
    const allocated_LE_fame = 0;
    let allocated_15_fame = 0;

    const fame_advanced = parseToFloat(dataTotals.req_vol.fame_advanced.toString());
    const allocated_LE_fame_advanced = 0;
    let allocated_15_fame_advanced = 0;

    const hvo_hro = parseToFloat(dataTotals.req_vol.hvo_hro.toString());
    const allocated_LE_hvo_hro = 0;
    let allocated_15_hvo_hro = 0;

    const hvo_hro_advanced = parseToFloat(dataTotals.req_vol.hvo_hro_advanced.toString());
    const allocated_LE_hvo_hro_advanced = 0;
    let allocated_15_hvo_hro_advanced = 0;

    for (const row of newData) {
      {
        // @ts-ignore
        // allocated_LE_gasoline_sub_mandate += parseFloat(row.allocated_quantity);
        // @ts-ignore
        // allocated_15_gasoline_sub_mandate += parseFloat(row.allocated_quantity);
      }
      // if (activeStep === 1)
      // {
      //   // @ts-ignore
      //   if (row.double_counted_norway == "Ja") {
      //     // @ts-ignore
      //     // allocated_LE_advanced_sub_mandate += parseFloat(row.allocated_quantity);
      //     // @ts-ignore
      //     // allocated_15_advanced_sub_mandate += parseFloat(row.allocated_quantity);
      //   }
      //   // @ts-ignore
      //   //allocated_LE_general_sub_mandate += parseFloat(row.allocated_quantity);
      //   // @ts-ignore
      //   allocated_15_general_sub_mandate += parseFloat(row.allocated_quantity);
      // }

      if (activeStep === 1) {
        // @ts-ignore
        // if (row.double_counted_norway == "Ja") {
        //   // @ts-ignore
        //   allocated_LE_general_sub_mandate += (parseFloat(row.allocated_quantity) * 2 );
        // } else {
        //   // @ts-ignore
        //   allocated_LE_general_sub_mandate += parseFloat(row.allocated_quantity);
        // }
        // @ts-ignore
        allocated_15_general_sub_mandate += parseToFloat(row.allocated_quantity);
      }
      if (activeStep === 2) {
        // @ts-ignore
        allocated_LE_above_mandate_req_by_cust += parseToFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_above_mandate_req_by_cust += parseToFloat(row.allocated_quantity);
      }

      if (activeStep === 3) {
        // @ts-ignore
        allocated_LE_above_mandate_after_fulfill += parseToFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_above_mandate_after_fulfill += parseToFloat(row.allocated_quantity);
      }

      if (activeStep === 4) {
        // @ts-ignore
        allocated_LE_vol_not_applicable_to_mandate += parseToFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_vol_not_applicable_to_mandate += parseToFloat(row.allocated_quantity);
      }
      // @ts-ignore
      if (row.double_counted_norway == "Ja" && row.biofuel_type == "FAME") {
        // @ts-ignore
        // allocated_LE_fame_advanced += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_fame_advanced += parseToFloat(row.allocated_quantity);
      }

      // @ts-ignore
      if (row.double_counted_norway == "Ja" && row.biofuel_type == "HVO/HRO") {
        // @ts-ignore
        allocated_15_hvo_hro_advanced += parseToFloat(row.allocated_quantity);
      }


      // @ts-ignore
      if (row.double_counted_norway == "Nei" && row.biofuel_type == "HVO/HRO") {
        // @ts-ignore
        // allocated_LE_hvo_hro += parseFloat(row.allocated_quantity);
        // // @ts-ignore
         allocated_15_hvo_hro += parseToFloat(row.allocated_quantity);
      }

      // @ts-ignore
      if (row.double_counted_norway == "Nei" && row.biofuel_type == "FAME") {
        // @ts-ignore
        // allocated_LE_fame += parseFloat(row.allocated_quantity);
        // @ts-ignore
        allocated_15_fame += parseToFloat(row.allocated_quantity);
      }

    }
    dataTotals.allocated_15.general_mandate = allocated_15_general_sub_mandate;
    if (dataTotals.remaining_vol.general_mandate != 0) {
      const current_table = (dataTotals.req_vol.general_req_vol - dataTotals.joint_fulfill.general_mandate - dataTotals.allocated_15.general_mandate);
      const roaduse_val = dataTotals.offroad_surplus.general_mandate;
      dataTotals.offroad_surplus.general_mandate = (current_table < roaduse_val ? current_table : roaduse_val);
    }

    dataTotals.remaining_vol.general_mandate =   dataTotals.req_vol.general_req_vol -
        dataTotals.allocated_15.general_mandate - dataTotals.offroad_surplus.general_mandate;

    console.log("rem vol" , dataTotals.req_vol.general_req_vol, " ",
        dataTotals.allocated_15.general_mandate, " ", dataTotals.offroad_surplus.general_mandate );

    dataTotals.allocated_15.above_mandate_req_by_cust = allocated_15_above_mandate_req_by_cust;
    dataTotals.remaining_vol.above_mandate_req_by_cust = dataTotals.req_vol.above_mandate_req_by_cust - allocated_15_above_mandate_req_by_cust;

    dataTotals.allocated_15.above_mandate_after_fulfill = allocated_15_above_mandate_after_fulfill;
    dataTotals.remaining_vol.above_mandate_after_fulfill = dataTotals.req_vol.above_mandate_after_fulfill - allocated_15_above_mandate_after_fulfill;

    dataTotals.allocated_15.vol_not_applicable_to_mandate = allocated_15_vol_not_applicable_to_mandate;
    dataTotals.remaining_vol.vol_not_applicable_to_mandate = dataTotals.req_vol.vol_not_applicable_to_mandate - allocated_15_vol_not_applicable_to_mandate;

    dataTotals.allocated_15.hvo_hro_advanced = allocated_15_hvo_hro_advanced;
    dataTotals.remaining_vol.hvo_hro_advanced = dataTotals.req_vol.hvo_hro_advanced - dataTotals.allocated_15.hvo_hro_advanced;

    dataTotals.allocated_15.fame_advanced = allocated_15_fame_advanced;
    dataTotals.remaining_vol.fame_advanced = dataTotals.req_vol.fame_advanced - dataTotals.allocated_15.fame_advanced;

    dataTotals.allocated_15.hvo_hro = allocated_15_hvo_hro;
    dataTotals.remaining_vol.hvo_hro = dataTotals.req_vol.hvo_hro - dataTotals.allocated_15.hvo_hro;

    dataTotals.allocated_15.fame = allocated_15_fame;
    dataTotals.remaining_vol.fame = dataTotals.req_vol.fame - dataTotals.allocated_15.fame;

    return dataTotals;
  };

  useEffect(() => {
    updateIsFetching(true);
    let newDataForStep;

    switch (activeStep) {
      default:
        newDataForStep = removeUnavailableBiotemplates(data);
        break;
    }
    // @ts-ignore
    setNewData(newDataForStep);

    fetchBiotemplateData(activeStep, newDataForStep);

    updateIsFetching(false);
  }, [data, activeStep]);

  useEffect(() => {
    // @ts-ignore
    const copy = newData.map(item => ({ ...item,  allocated_quantity: parseToFloat(item.allocated_quantity)}));

    // @ts-ignore
    const newArray = {
      // @ts-ignore
      biotemplates: copy.filter(item => item.allocated_quantity > 0).map(item => ({id: item.id, available_quantity: item.available_quantity, allocated_quantity: item.allocated_quantity}))
    };
    // @ts-ignore
    onUpdateData(newArray, "bio");

    if (activeStep === 4) {
      const params: Requirement = {
        construction_volume_requirement: {},
      };

      stepTwosData.forEach(item => {
        // @ts-ignore
        if (params.construction_volume_requirement[item.biotemplate_id]) {
          // @ts-ignore
          params.construction_volume_requirement[item.biotemplate_id] += parseToFloat(item.allocated_quantity);
        } else {
          // @ts-ignore
          params.construction_volume_requirement[item.biotemplate_id] = parseToFloat(item.allocated_quantity);
        }
      });

      stepThreesData.forEach(item => {
        // @ts-ignore
        if (params.construction_volume_requirement[item.biotemplate_id]) {
          // @ts-ignore
          params.construction_volume_requirement[item.biotemplate_id] += parseToFloat(item.allocated_quantity);
        } else {
          // @ts-ignore
          params.construction_volume_requirement[item.biotemplate_id] = parseToFloat(item.allocated_quantity);
        }
      });

      stepFoursData.forEach(item => {
        // @ts-ignore
        if (params.construction_volume_requirement[item.biotemplate_id]) {
          // @ts-ignore
          params.construction_volume_requirement[item.biotemplate_id] += parseToFloat(item.allocated_quantity);
        } else {
          // @ts-ignore
          params.construction_volume_requirement[item.biotemplate_id] = parseToFloat(item.allocated_quantity);
        }
      });

      console.log("newArray");
      console.log(newArray);

      const updatedArray = { biotemplates: [] };

      // Create a map for faster lookup
      const newArrayMap = {};
      newArray.biotemplates.forEach(item => {
        newArrayMap[item.id] = item.allocated_quantity;
      });

      // Update newArray based on stepFivesData

      // Add the remaining entries from newArrayMap to updatedArray
      Object.entries(newArrayMap).forEach(([id, allocated_quantity]) => {
        // @ts-ignore
        updatedArray.biotemplates.push({ id: Number(id), allocated_quantity: String(allocated_quantity) });
      });

      console.log("updatedArray");
      console.log(updatedArray);

      updatedArray.biotemplates.forEach(item => {
        // @ts-ignore
        params.construction_volume_requirement[item.id] = item.allocated_quantity;
      });

      // @ts-ignore
      onUpdateRequirement(params);
    }

  }, [activeStep, newData]);

  useEffect(() => {

    const dataTotals: OverViewProps = updateDataTotals(responseOverViewData, newData);

    const newArray = {
      // @ts-ignore
      overview: dataTotals,
      id: stepDataId
    };

    setOverViewData(dataTotals);
    // @ts-ignore
    onUpdateData(newArray, "overview");
  }, [newData]);

  return (
      <>
        <div className={classes.tableContainer}>
          {activeStep > 1 && (
              <>
                <Typography variant="h4">Step 2 Data</Typography><br/>
                <SmartTable
                    headCells={prevTableHeadings}
                    rows={stepTwosData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br/><br/>
              </>
          )}

          {activeStep > 2 && (
              <>
                <Typography variant="h4">Step 3 Data</Typography><br/>
                <SmartTable
                    headCells={prevTableHeadings}
                    rows={stepThreesData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br/><br/>
              </>
          )}

          {activeStep > 3 && (
              <>
                <Typography variant="h4">Step 4 Data</Typography><br/>
                <SmartTable
                    headCells={prevTableHeadings}
                    rows={stepFoursData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br/><br/>
              </>
          )}
          {activeStep > 4 && (
              <>
                <Typography variant="h4">Step 5 Data</Typography><br/>
                <SmartTable
                    headCells={prevTableHeadings}
                    rows={stepFivesData}
                    verticalOverflow={true}
                    whiteHeader={true}
                /><br/><br/>
              </>
          )}
          <Typography variant="h4">Allocation Table</Typography><br/>
          <SmartTable
              headCells={tableHeadings}
              // rows={data.length === 0 ? null : getData(data, activeStep)}
              // rows={getData(data, activeStep)}
              rows={getData(newData, activeStep)}
              editableProps={{
                id: "allocated_quantity",
                getValue: getAllocated,
                onChange: handleOnChange,
                onBlur: handleOnBlur,
              }}
              verticalOverflow={true}
              whiteHeader={true}
          />
        </div>
        <OverviewPanel className={classes.overviewPanel}>
          <OverviewTableContainer data={overViewData} step={activeStep}/>
        </OverviewPanel>
      </>
  );
};

// @ts-ignore
export default EditableTable;
