import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ButtonRounded from "../../components/ButtonRounded";
import ModalContainer from "../../components/ModalContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {},
        container: {
            padding: theme.spacing(2),
        },
        btnContainer: {
            padding: theme.spacing(2, 0),
        },
        btnCancel: {},
    })
);

interface ModalToggleMandateProps {
    open: boolean;
    onClose: () => void;
}

const ModalToggleMandate: React.FC<ModalToggleMandateProps> = ({
   open,
   onClose,
}) => {
    const [isError, setIsError] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            title="Mandate Enabled"
        >
            <div
                style={{
                    textAlign: "center",
                    padding: "16px",
                }}
            >
                <Typography variant="body1" gutterBottom>
                    Both off-road and marine mandates are enabled. Remember that you must specify sold volumes for these mandates from the same pool of excise duty in the edit volumes section.
                </Typography>
                <Grid
                    container
                    spacing={2}
                    alignContent={"center"}
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Grid item>
                        <br></br>
                        <ButtonRounded
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                            disabled={isError}
                        >
                            Continue
                        </ButtonRounded>
                    </Grid>
                </Grid>
                {isError && (
                    <Typography align="center" variant="body2" color="error">
                        Some error occurred.
                    </Typography>
                )}
            </div>
        </ModalContainer>
    );
};

export default ModalToggleMandate;
