/**
 * Get confirmation state
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

interface IgetConfirmState {
  taxYear: number;
}

export const checkDisableConfirm = async ({ taxYear }: IgetConfirmState) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
          `${BASE_URL}/api/sustainability_reports/check_disable_confirm?tax_year=${taxYear}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
      );

      if (response.status === 200) {
        const data = await response.json();
        return data.state;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export const getConfirmState = async ({ taxYear }: IgetConfirmState) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports/confirm_state?tax_year=${taxYear}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export const checkConfirmState = async ({ taxYear }: IgetConfirmState) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
          `${BASE_URL}/api/sustainability_reports/confirm_state_check?tax_year=${taxYear}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
      );

      if (response) {
        const data = await response.json();
        return data.state;
      } else {
        return false;
      }
    } catch {
      return false; // network error
    }
  } else {
    return false; // No token
  }
};

export default getConfirmState;
