import React, {Component, useEffect, useState, useRef} from "react";
import ButtonRounded from "../../../components/ButtonRounded";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ToggleButton from "../../../components/ToggleButton";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
// @ts-ignore
import { State } from "../../../reducers/reducer";
import { useSelector } from "react-redux";
import getAllocationPageData from "../../../api/sustainability/allocation/collaboration/get";
import {useHistory, useRouteMatch} from "react-router-dom";
import {startAllocation} from "../../../reducers/sustainability/allocation/multiStep";
import { hasRoadData, deleteRoadUseData } from "../../../api/sustainability/allocation/roadUse/data";
import {
    hasOffRoadData,
    deleteOffRoadUseData,
    toggleOffroadMandateStatus, toggleOffroadJointFulfillmentStatus
} from "../../../api/sustainability/allocation/offRoadUse/data";
import {
    hasMarineData,
    deleteMarineData,
    toggleMarineMandateStatus
} from "../../../api/sustainability/allocation/marineUse/data";
// @ts-ignore
import { toggleSimulation } from "../../../reducers/sustainability/main";
import { useDispatch } from "react-redux";
import resetAllocation from "../../../api/sustainability/allocation/roadUse/stepData";
import {resetAllocationOffroad} from "../../../api/sustainability/allocation/offRoadUse/stepData";
import getDownloadReport, {
    Tfiletype,
    Tmandate_type
} from "../../../api/sustainability/reports/getDownloadReport";
import ModalInfo from "../../../components/modal/ModalInfo";
import { getConfirmState, checkConfirmState } from "../../../api/sustainability/reports/getConfirmState";
import ModalConfirmDelivery from "../../../views/DashboardView/SustainabilityView/ReportsView/ModalConfirmDelivery";
import ModalDeleteVolumeInMandate from "../../../components/FilesTable/ModalDeleteVolumeInMandate";
import {getStoredState} from "redux-persist";
import {store} from "../../../reducers/store";
import {emptyKey} from "../../../reducers/sustainability/allocation/roaduseAllocation";
import {toggleRoaduseMandateStatus, toggleRoaduseJointFulfillmentStatus} from "../../../api/sustainability/allocation/roadUse/data";
import * as events from "events";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
        },
        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        cardAllocation: {
            flex: "1", // Takes 2/3 of the available space
        },

        // Style for the second card with 1/3 space
        cardReport: {
            flex: "1", // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "11px",
                fontWeight: 700,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
    })
);

const MandateOverviewContainer: React.FC = () => {
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const isSimulation = useSelector((state: State) => state.sustainability.main.isSimulation);
    const activePeriod = useSelector((state: State) => state.sustainability.main.activePeriod);

    const [isRoadUseToggleButtonEnabled, setIsRoadUseToggleButtonEnabled] = useState(true);
    const [isOffRoadToggleButtonEnabled, setIsoffRoadToggleButtonEnabled] = useState(true);
    const [isMarineToggleButtonEnabled, setIsMarineToggleButtonEnabled] = useState(true);

    const [isRoadUseJoint, setIsRoadUseJoint] = useState(false);
    const [isOffRoadJoint, setIsoffRoadJoint] = useState(false);
    const [isMarineJoint, setIsMarineJoint] = useState(false);

    const [areButtonsVisibleRoadUse, setAreButtonsVisibleRoadUse] = useState(true);
    const [areButtonsVisibleOffRoad, setAreButtonsVisibleOffRoad] = useState(true);
    const [areButtonsVisibleMarine, setAreButtonsVisibleMarine] = useState(true);
    const [marineDataId, setMarineDataId] = useState<any>(null);
    const [marineHasStep, setMarineHasStep] = useState<boolean>(false);
    const [roadUseId, setRoadUseId] = useState<any>(null);
    const [roadUseHasStep, setRoadUseHasStep] = useState<boolean>(false);
    const [offRoadUseId, setOffRoadUseId] = useState<any>(null);
    const [offRoadUseHasStep, setOffRoadUseHasStep] = useState<boolean>(false);
    const [confirmState, setConfirmState] = useState<boolean>(false);
    const [showModalDeleteRoaduse, setShowModalDeleteRoaduse] = useState<boolean>(false);
    const [showModalDeleteOffRoad, setShowModalDeleteOffRoad] = useState<boolean>(false);
    const [showModalDeleteMarine, setShowModalDeleteMarine] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [
        showConfirmDeliveryModal,
        setShowConfirmDeliveryModal,
    ] = useState<boolean>(false);

    const openErrorModal = (msg: string) => {
        setErrorMessage(msg);
        setShowErrorModal(true);
    };
    const closeErrorModal = () => {
        setErrorMessage("");
        setShowErrorModal(false);
    };

    const openConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(true);
    };

    const closeConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(false);
    };

    const openModalDeleteRoaduse = () => {
        setShowModalDeleteRoaduse(true);
    };
    const closeModalDeleteRoaduse = () => {
        setShowModalDeleteRoaduse(false);
    };

    const openModalDeleteOffRoad = () => {
        setShowModalDeleteOffRoad(true);
    };
    const closeModalDeleteOffRoad = () => {
        setShowModalDeleteOffRoad(false);
    };
    const openModalDeleteMarine = () => {
        setShowModalDeleteMarine(true);
    };
    const closeModalDeleteMarine = () => {
        setShowModalDeleteMarine(false);
    };
    const handleRoadUseVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/roadusevolumes`);
        }
    };

    const handleStartAllocation = async () => {
        const response = await resetAllocation();
        dispatch(emptyKey())
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/roaduse/allocation`);
            }
        } else {
            alert("Error occured when reseting allocation");
        }

    };

    // Change the function name later
    const handleContinueAllocation = async () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/roaduse/allocation`);
        }
    };
    const toggleRoaduseMandateState =  (label: string) => {
        const isEnable = label !== "Disabled"
        setIsRoadUseToggleButtonEnabled(isEnable);
        toggleRoaduseMandateStatus(isEnable)
    };

    const toggleRoaduseJointfulfullmentState =  (label: string) => {
        const isEnable = label !== "Own Fullfillment"
        setIsRoadUseJoint(isEnable);
        toggleRoaduseJointFulfillmentStatus(isEnable)
    };

    const toggleOffroadMandateState =  (label: string) => {
        const isEnable = label !== "Disabled"
        setIsoffRoadToggleButtonEnabled(isEnable)
        toggleOffroadMandateStatus(isEnable)
    };

    const toggleMarineMandateState =  (label: string) => {
        const isEnable = label !== "Disabled"
        setIsMarineToggleButtonEnabled(isEnable);
        toggleMarineMandateStatus(isEnable)
    };

    const handleOffRoadReport = async () => {
        const response = await resetAllocationOffroad();
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/offroad/allocation`);
            }
        }
    };
    const handleContinueAllocationOffroad = async () => {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/offroad/allocation`);
            }
    };
    const handleOffRoadVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/offroadvolumes`);
        }
    };
    const handleMarineVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/marinevolumes`);
        }
    };

    const handleDraftReport = (mandateType:string) => {
        if (match) {
            history.push(`/sustainability/reports/${mandateType}`);
        }
    };

    const handleViewDraft = () => {
        if (match) {
            history.push(`/sustainability/reports`);
        }
    };

    const checkData = async () => {
        const roadUse = await hasRoadData();
        if (roadUse) {
            setRoadUseId(roadUse.id);
            setRoadUseHasStep(!!roadUse.last_active_step);
            setIsRoadUseToggleButtonEnabled(roadUse.mandate_enabled);
            setIsRoadUseJoint(roadUse.joint_fulfillment);
        }

        const offRoadUse = await hasOffRoadData();
        if (offRoadUse) {
            setOffRoadUseId(offRoadUse.id);
            setOffRoadUseHasStep(!!offRoadUse.last_active_step);
            setIsoffRoadToggleButtonEnabled(offRoadUse.mandate_enabled)

        }

        const marine = await hasMarineData();
        if (marine) {
            setMarineDataId(marine.id);
            setMarineHasStep(!!marine.last_active_step);
            setIsMarineToggleButtonEnabled(marine.mandate_enabled)
        }

        await checkConfirmationStatus();
    };
    const deleteRoaduseVolume = async () => {
         await deleteData("road-use", roadUseId)
    }
    const deleteOffRoadVolume = async (mandate: string) => {
        if(mandate=="marine"){
            await deleteData(mandate, marineDataId)
        }
        else{
            await deleteData(mandate, offRoadUseId)
        }
    }
    const deleteMarineVolume = async () => {
        await deleteData("marine", marineDataId)
    }
    const deleteData = async ( type: string, id: any ) => {
        switch (type) {
            case "road-use" :
                await deleteRoadUseData(id);
                await resetAllocation();
                break;
            case "offroad" :
                await deleteOffRoadUseData(id, type);
                await resetAllocationOffroad();
                break;
            case "marine" :
                await deleteOffRoadUseData(id, type);
                // await deleteMarineData(id);
                break;
            default :
        }
        await checkData();
        closeModalDeleteRoaduse(); // Close the modal after deletion
    };
    const isMounted = useRef(true);

    const handleDownload = async (type: Tfiletype, mandate_type: Tmandate_type) => {
        const response = await getDownloadReport({
            type,
            mandate_type,
            // @ts-ignore
            taxYear: activePeriod.year,
        });
        if (response === null) {
            openErrorModal(
                "Failed to download file: Possible errors: no file for selected year or other errors."
            );
        }

        if (response !== null) {
            // emulate file download by clicking on an <a /> link
            const url = window.URL.createObjectURL(response);
            const fileName =
                type.indexOf("sub") > -1
                    ? "sustainability-subreport"
                    : "sustainability-report";
            const fileExt = type.indexOf("excel") > -1 ? "xlsx" : "json";

            const a = document.createElement("a");
            a.href = url;
            // @ts-ignore
            a.download = `${fileName}-${mandate_type}-${activePeriod.year}.${fileExt}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    };

    const handleConfirmationStatus = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            const res = await getConfirmState({ taxYear: activePeriod.year });
            if (res === null) {
                openErrorModal(
                    "Failed to confirm delivery. Possible errors: correct reporting year is not selected or other errors."
                );
            } else {
                openErrorModal(
                    "Delivery Confirmed."
                );
            }
            checkData();
        }
    };

    const checkConfirmationStatus = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            const confirmState = await checkConfirmState({taxYear: activePeriod.year});
            setConfirmState(confirmState);
        }
    };

    useEffect(() => {
        setAreButtonsVisibleRoadUse(isRoadUseToggleButtonEnabled);
        setAreButtonsVisibleOffRoad(isOffRoadToggleButtonEnabled);
        setAreButtonsVisibleMarine(isMarineToggleButtonEnabled);
    }, [isSimulation, isRoadUseToggleButtonEnabled, isOffRoadToggleButtonEnabled, isMarineToggleButtonEnabled]);

    useEffect(() => {
        const params = {
            allocation: isSimulation ? "Simulation" : "Reporting",
            fulfilment: "own",
            // @ts-ignore
            taxYearId: activePeriod.id,
        };

        getAllocationPageData(params);
        checkData();

    }, [isSimulation, activePeriod]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            checkData();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    // @ts-ignore
    // @ts-ignore
    return (
        <div>
            <h1>Bio mandate reporting</h1>
            <div className={classes.cardContainer}>
                <Card className={`${classes.card} ${classes.cardReport}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.toggleCardTitle}>
                            <FormattedMessage id="allocationView.mainView.allocationMode"/>
                        </Typography>

                        <ToggleButton
                            labels={["Reporting", "Simulation"]}
                            defaultLabelIndex={isSimulation ? 1 : 0}
                            handleChange={() => dispatch(toggleSimulation())}
                        />
                    </CardContent>
                </Card>
                <Card className={`${classes.card} ${classes.cardAllocation}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.cardTitle}>
                            <p>Report File and Actions</p>
                        </Typography>
                        <Grid container spacing={3}>
                            {/*<Grid item xs={6}>*/}
                            {/*    <ButtonRounded*/}
                            {/*        variant="contained"*/}
                            {/*        color="primary"*/}
                            {/*        fullWidth*/}
                            {/*        onClick={() => handleDownload("report_excel")}*/}
                            {/*    >*/}
                            {/*        Download Excel Rep*/}
                            {/*    </ButtonRounded>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={4}>*/}
                            {/*    <ButtonRounded*/}
                            {/*        variant="contained"*/}
                            {/*        color="primary"*/}
                            {/*        fullWidth*/}
                            {/*        onClick={() => handleDownload("report_json")}*/}
                            {/*    >*/}
                            {/*        Download JSON File*/}
                            {/*    </ButtonRounded>*/}
                            {/*</Grid>*/}
                            <Grid item xs={6}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={openConfirmDeliveryModal}
                                    disabled={!!confirmState}
                                    // className={classes.btnDownloads}
                                >
                                    {confirmState ? (
                                        <FormattedMessage id="sustainabilityView.reportsView.confirmed" />
                                    ) : (
                                        <FormattedMessage id="loginView.confirm" />
                                    )}{" "}
                                    <FormattedMessage id="sustainabilityView.reportsView.delivery" />
                                </ButtonRounded>

                            </Grid>
                            <Grid item xs={6}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={()=>handleDraftReport('roaduse')}
                                    className={classes.buttonMargin}
                                    disabled={false}
                                >
                                    Report overview
                                </ButtonRounded>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>


                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Road use mandate for 2023</h1>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                {areButtonsVisibleRoadUse && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>)}
                                {areButtonsVisibleRoadUse && (<Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseVolume}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId !== null}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseVolume}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={roadUseId === null}
                                            // onClick={async () => await deleteData("road-use", roadUseId)}
                                            onClick = {()=> openModalDeleteRoaduse()}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleStartAllocation}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null || roadUseHasStep}
                                        >
                                                Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleStartAllocation}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null || !roadUseHasStep}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleContinueAllocation}
                                            disabled={roadUseId === null || !roadUseHasStep}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>
                                    {/*<Grid item xs={4}>*/}
                                    {/*    <ButtonRounded*/}
                                    {/*        variant="contained"*/}
                                    {/*        color="primary"*/}
                                    {/*        fullWidth*/}
                                    {/*        onClick={handleViewDraft}*/}
                                    {/*        disabled={roadUseId === null || !roadUseHasStep}*/}
                                    {/*    >*/}
                                    {/*        View Draft Report*/}
                                    {/*    </ButtonRounded>*/}
                                    {/*</Grid>*/}
                                </Grid>)}
                                {showModalDeleteRoaduse &&(
                                    <>
                                        <ModalDeleteVolumeInMandate
                                            open={showModalDeleteRoaduse}
                                            handleDelete={async ()=>{
                                                // await deleteData("road-use", roadUseId);
                                                await deleteRoadUseData(roadUseId);
                                                await resetAllocation();
                                                await checkData();
                                                window.location.reload();
                                            }}
                                            onClose={closeModalDeleteRoaduse}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>

                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Enabled", "Disabled"]}
                                    defaultLabelIndex={isRoadUseToggleButtonEnabled ? 0 : 1}
                                    handleChange={toggleRoaduseMandateState}
                                />
                                </div>
                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isRoadUseJoint ? 0 : 1}
                                    handleChange={toggleRoaduseJointfulfullmentState}
                                />
                                </div>
                                {/*<ButtonRounded*/}
                                {/*    variant="contained"*/}
                                {/*    color="primary"*/}
                                {/*    fullWidth*/}
                                {/*    onClick={() => handleDownload("report_excel","roaduse")}*/}
                                {/*>*/}
                                {/*    Download Excel Rep*/}
                                {/*</ButtonRounded>*/}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>



                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Off-road mandate for 2023</h1>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                {areButtonsVisibleOffRoad && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>)}
                                {areButtonsVisibleOffRoad && (<Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadVolume}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId !== null}
                                        >
                                            Add Volumes
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadVolume}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Edit Volumes
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            //onClick={async () => await deleteData("off-road-use", offRoadUseId)}
                                            onClick={()=>openModalDeleteOffRoad()}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadReport}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || offRoadUseHasStep}
                                        >
                                            Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadReport}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || !offRoadUseHasStep}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleContinueAllocationOffroad}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || !offRoadUseHasStep}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>

                                </Grid>)}
                                {showModalDeleteOffRoad &&(
                                    <>
                                        <ModalDeleteVolumeInMandate
                                            open={showModalDeleteOffRoad}
                                            handleDelete={()=>{
                                                deleteOffRoadVolume("offroad").then(() => {
                                                    closeModalDeleteOffRoad(); // Close the modal after deletion
                                                    window.location.reload(); // Reload the page after successful deletion
                                                });


                                            }}
                                            onClose={closeModalDeleteOffRoad}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>
                                <div className={classes.toggleButtonMargin}>
                                    <ToggleButton
                                        labels={["Enabled", "Disabled"]}
                                        defaultLabelIndex={isOffRoadToggleButtonEnabled ? 0 : 1}
                                        handleChange={toggleOffroadMandateState}
                                    />
                                </div>
                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isOffRoadJoint ? 1 : 0}
                                    handleChange={() => setIsoffRoadJoint(!isOffRoadJoint)}
                                />
                                </div>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>



                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Marine mandate for 2023</h1>
                                <p>The road use mandate in 2023 is 17 %. In addition to the general mandate level, the
                                    road use mandate also contains separate sub-mandates for gasoline (4 %) and minimum
                                    volume of biofuels with advanced sustainability criteria (12,5 %).</p>
                                {areButtonsVisibleMarine && (<Typography variant="h5" className={classes.toggleCardTitle}>
                                    <FormattedMessage id="allocationView.mainView.allocationMode"/>
                                </Typography>)}
                                {areButtonsVisibleMarine && (<Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineVolume}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId !== null}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineVolume}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            // onClick={async () => await deleteData("marine", marineDataId)}
                                            onClick={()=>openModalDeleteMarine()}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => console.log("clicked")}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => console.log("clicked")}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => console.log("clicked")}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>

                                </Grid>)}
                                {showModalDeleteMarine &&(
                                    <>
                                        <ModalDeleteVolumeInMandate
                                            open={showModalDeleteMarine}
                                            handleDelete={()=>{
                                                deleteOffRoadVolume("marine").then(() => {
                                                    closeModalDeleteOffRoad(); // Close the modal after deletion
                                                    window.location.reload(); // Reload the page after successful deletion
                                                });
                                            }}
                                            onClose={closeModalDeleteMarine}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid>
                        </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>
                                <div className={classes.toggleButtonMargin} >
                                    <ToggleButton
                                        labels={["Enabled", "Disabled"]}
                                        defaultLabelIndex={isMarineToggleButtonEnabled ? 0 : 1}
                                        handleChange={toggleMarineMandateState}
                                    />
                                </div>
                                <div className={classes.toggleButtonMargin} >
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isMarineJoint ? 1 : 0}
                                    handleChange={() => setIsMarineJoint(!isMarineJoint)}

                                />
                                </div>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            <ModalInfo
                text={errorMessage}
                open={showErrorModal}
                onClose={closeErrorModal}
            />
            <ModalConfirmDelivery
                open={showConfirmDeliveryModal}
                onClose={closeConfirmDeliveryModal}
                onConfirm={() => {
                    closeConfirmDeliveryModal();
                    handleConfirmationStatus();
                }}
            />
        </div>
    );
};
export default MandateOverviewContainer;
