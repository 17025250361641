/**
 * Get users list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const hasOffRoadData = async () => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/check_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getOffRoadUseData = async (param: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes?mandate=${param}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getOffRoadUseCreateData = async (params: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getOffRoadUseEditData = async (params: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const deleteOffRoadUseData = async (id: any, mandate: string) => {
  const token = getToken();
  const params = {
    id,
    mandate
  };
  if (token && isAdmin()) {
    try {
      // @ts-ignore
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};
export const toggleOffroadMandateStatus = async (mandate_enabled: boolean) => {
  const token = getToken();
  const params = {
    mandate_enabled,
  };
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/toggle_offroad_mandate_settings`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const toggleOffroadJointFulfillmentStatus = async (joint_fulfillment: boolean) => {
  const token = getToken();
  const params = {
    joint_fulfillment,
  };
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/toggle_offroad_mandate_settings`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};
export default getOffRoadUseData;
