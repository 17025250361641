import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";
export const getMarineDraftReport = async () => {
    const token = getToken();
    if (token && isAdmin()) {
        try {
            const response = await fetch(`${BASE_URL}/api/draft_report?mandate=marine`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                return data;
            } else {
                return null;
            }
        } catch {
            return null; // network error
        }
    } else {
        return null; // No token or not admin
    }
};
