import React, {Component, useEffect, useState, useRef} from "react";
import ButtonRounded from "../../../components/ButtonRounded";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ToggleButton from "../../../components/ToggleButton";
import {makeStyles, createStyles, Theme} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";
// @ts-ignore
import { State } from "../../../reducers/reducer";
import { useSelector } from "react-redux";
import getAllocationPageData from "../../../api/sustainability/allocation/collaboration/get";
import {useHistory, useRouteMatch} from "react-router-dom";
import {startAllocation} from "../../../reducers/sustainability/allocation/multiStep";
import { hasRoadData, deleteRoadUseData } from "../../../api/sustainability/allocation/roadUse/data";
import {
    hasOffRoadData,
    deleteOffRoadUseData,
    toggleOffroadMandateStatus, toggleOffroadJointFulfillmentStatus, getMandateStatuses
} from "../../../api/sustainability/allocation/offRoadUse/data";
import {
    hasMarineData,
    deleteMarineData,
    toggleMarineMandateStatus, toggleMarineJointFulfillmentStatus
} from "../../../api/sustainability/allocation/marineUse/data";
// @ts-ignore
import { toggleSimulation } from "../../../reducers/sustainability/main";
import { useDispatch } from "react-redux";
import resetAllocation from "../../../api/sustainability/allocation/roadUse/stepData";
import {resetAllocationOffroad} from "../../../api/sustainability/allocation/offRoadUse/stepData";
import getDownloadReport, {
    Tfiletype,
    Tmandate_type
} from "../../../api/sustainability/reports/getDownloadReport";
import ModalInfo from "../../../components/modal/ModalInfo";
import { getConfirmState, checkConfirmState } from "../../../api/sustainability/reports/getConfirmState";
import { checkDisableConfirm } from "../../../api/sustainability/reports/getConfirmState";
import ModalConfirmDelivery from "../../../views/DashboardView/SustainabilityView/ReportsView/ModalConfirmDelivery";
import ModalDeleteVolumeInMandate from "../../../components/FilesTable/ModalDeleteVolumeInMandate";
import {getStoredState} from "redux-persist";
import {store} from "../../../reducers/store";
import {emptyKey} from "../../../reducers/sustainability/allocation/roaduseAllocation";
import {emptyKey as emptyKeyOffroad} from "../../../reducers/sustainability/allocation/offRoadAllocation";
import {emptyKey as emptyKeyMarine} from "../../../reducers/sustainability/allocation/marineAllocation";
import {toggleRoaduseMandateStatus, toggleRoaduseJointFulfillmentStatus} from "../../../api/sustainability/allocation/roadUse/data";
import * as events from "events";
import { resetAllocationMarine } from "../../../api/sustainability/allocation/marineUse/stepData";
import parseAndFormat from "../../../components/texts/parseAndFormat";
import ModalRestartAllocation from "../../../components/FilesTable/ModalRestartAllocation";
import ModalToggleMandate from "../../../components/FilesTable/ModalToggleMandate";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        h2: {
            [theme.breakpoints.down("md")]: {
                fontSize: "32px",
            },
        },
        btn: {
            minWidth: "200px",
            "&:not(:first-child)": {
                marginLeft: "20px",
                [theme.breakpoints.down("md")]: {
                    marginLeft: "10px",
                },
            },
        },
        buttonMargin: {
            marginBottom: theme.spacing(1), // Adjust the spacing as needed
        },
        toggleButtonMargin: {
            marginBottom: theme.spacing(2), // Adjust the bottom margin as needed
        },
        toggleCardContainer: {
            marginTop: theme.spacing(3),
        },
        toggleCardContent: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        toggleCardTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        card: {
            margin: "25px",
        },
        cardCommon: {
            marginTop: theme.spacing(3),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        cardContent: {
            padding: theme.spacing(3.75),
            paddingTop: theme.spacing(4),
            "&:last-child": {
                paddingBottom: theme.spacing(3.75),
            },
        },
        cardTitle: {
            marginBottom: theme.spacing(2.5),
        },
        cardSubTitle: {
            fontSize: theme.typography.h5.fontSize,
            marginBottom: theme.spacing(2.5),
            marginTop: theme.spacing(2),
        },
        cardContainer: {
            display: "flex",
            alignItems: "stretch", // Ensure both cards have the same height
            marginBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        cardAllocation: {
            flex: "1", // Takes 2/3 of the available space
        },

        // Style for the second card with 1/3 space
        cardReport: {
            flex: "1", // Takes 1/3 of the available space
        },
        tableTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
        outputTableTopLabel: {
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        table: {
            "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.spacing(4),
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                fontSize: "11px",
                fontWeight: 700,
            },
        },
        iconBtn: {
            padding: 0,
            marginLeft: theme.spacing(2),
        },
        companyName: {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(3),
        },
        addButton: {
            minWidth: "200px",
        },
        circularProgress: {
            margin: "80px",
            width: "48px",
            height: "48px",
        },
        soldVolumesTotal: {
            "& .MuiTableCell-root": {
                fontWeight: 700,
                paddingTop: theme.spacing(1.5),
                paddingBottom: theme.spacing(1.5),
                paddingRight: theme.spacing(3.8),
            },
        },
        clearfixAfter: {
            clear: 'both',
            content: '',
            display: 'block',
            height: 10,
        },
        containerArrowProgressbar: {
            fontFamily: "'Lato', sans-serif",
            width: '1050px',
            margin: '0 auto',
        },
        wrapper: {
            display: 'table-cell',
            height: '80px',
            verticalAlign: 'middle',
            paddingLeft: "8px",
        },
        arrowSteps: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .step': {
                fontSize: '16px',
                textAlign: 'center',
                color: '#666',
                cursor: 'default',
                margin: '0 3px',
                padding: '25px 10px 10px 30px',
                minWidth: '250px',
                position: 'relative',
                backgroundColor: '#d9e3f7',
                userSelect: 'none',
                '&:after, &:before': {
                    content: '" "',
                    position: 'absolute',
                    top: '0',
                    width: 0,
                    height: 0,
                    right: '-16px',
                    borderTop: '19px solid transparent',
                    borderBottom: '17px solid transparent',
                    borderLeft: '17px solid #d9e3f7',
                    zIndex: 2,
                    transition: 'border-color 0.2s ease',
                },
                '&:before': {
                    left: 0,
                    borderLeft: '17px solid #fff',
                    zIndex: 0,
                },
                '&:first-child:before': {
                    border: 'none',
                },
                '&:first-child': {
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                },
                '& span': {
                    position: 'relative',
                    top: '-17px'
                },
                '&.done span:before': {
                    opacity: 1,
                    transition: 'opacity 0.3s ease 0.5s',
                },
                '&.volumes-added': {
                    color: '#fff',
                    backgroundColor: '#8fcc60',
                    '&:after': {
                        borderLeft: '17px solid #8fcc60',
                    },
                },
                '&.volumes-not-added': {
                    color: '#fff',
                    backgroundColor: '#ff4319',
                    '&:after': {
                        borderLeft: '17px solid #ff4319',
                    },
                },
                '&.allocation-completed': {
                    color: '#fff',
                    backgroundColor: '#8fcc60',
                    '&:after': {
                        borderLeft: '17px solid #8fcc60',
                    },
                },
                '&.allocation-not-started': {
                    color: '#424b5a',
                    backgroundColor: '#c2d1d9',
                    '&:after': {
                        borderLeft: '17px solid #c2d1d9',
                    },
                },
                '&.allocation-started': {
                    color: '#fff',
                    backgroundColor: '#ffd588',
                    '&:after': {
                        borderLeft: '17px solid #ffd588',
                    },
                },
                '&.report-submitted': {
                    color: '#fff',
                    backgroundColor: '#8fcc60',
                    '&:after': {
                        borderLeft: '17px solid #8fcc60',
                    },
                },
                '&.report-not-submitted': {
                    color: '#fff',
                    backgroundColor: '#ffd588',
                    '&:after': {
                        borderLeft: '17px solid #ffd588',
                    },
                },
                '&.report-not-initiated': {
                    color: '#424b5a',
                    backgroundColor: '#c2d1d9',
                    '&:after': {
                        borderLeft: '17px solid #c2d1d9',
                    },
                },
            },
        },
    })
);

const MandateOverviewContainer: React.FC = () => {
    const classes = useStyles();
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isRestartRoaduseModalOpen, setIsRestartRoaduseModalOpen] = useState(false);
    const [isRestartOffroadModalOpen, setIsRestartOffroadModalOpen] = useState(false);
    const [isRestartMarineModalOpen, setIsRestartMarineModalOpen] = useState(false);
    const [isToggleOffroadMarineModalOpen, setIsToggleOffroadMarineModalOpen] = useState(false);

    const handleCloseToggleOffroadMarineModal = () => {
        setIsToggleOffroadMarineModalOpen(false);
    };

    const handleCloseRestartRoaduseModal = () => {
        setIsRestartRoaduseModalOpen(false);
    };

    const handleConfirmAndRestartRoaduse = async () => {
        handleCloseRestartRoaduseModal();
        await handleStartAllocation();
    };

    const handleCloseRestartOffroadModal = () => {
        setIsRestartOffroadModalOpen(false);
    };

    const handleConfirmAndRestartOffroad = async () => {
        handleCloseRestartOffroadModal();
        await handleOffRoadReport();
    };

    const handleCloseRestartMarineModal = () => {
        setIsRestartMarineModalOpen(false);
    };

    const handleConfirmAndRestartMarine = async () => {
        handleCloseRestartMarineModal();
        await handleMarineReport();
    };

    const isSimulation = useSelector((state: State) => state.sustainability.main.isSimulation);
    const activePeriod = useSelector((state: State) => state.sustainability.main.activePeriod);

    const [isRoadUseToggleButtonEnabled, setIsRoadUseToggleButtonEnabled] = useState(true);
    const [isOffRoadToggleButtonEnabled, setIsoffRoadToggleButtonEnabled] = useState(true);
    const [isMarineToggleButtonEnabled, setIsMarineToggleButtonEnabled] = useState(true);

    const [isRoadUseJoint, setIsRoadUseJoint] = useState(false);
    const [isOffRoadJoint, setIsOffRoadJoint] = useState(false);
    const [isMarineJoint, setIsMarineJoint] = useState(false);

    const [areButtonsVisibleRoadUse, setAreButtonsVisibleRoadUse] = useState(true);
    const [areButtonsVisibleOffRoad, setAreButtonsVisibleOffRoad] = useState(true);
    const [areButtonsVisibleMarine, setAreButtonsVisibleMarine] = useState(true);
    const [marineDataId, setMarineDataId] = useState<any>(null);
    const [marineHasStep, setMarineHasStep] = useState<boolean>(false);
    const [roadUseId, setRoadUseId] = useState<any>(null);
    const [roadUseHasStep, setRoadUseHasStep] = useState<boolean>(false);
    const [roadUseVolConfirmed, setRoadUseVolConfirmed] = useState<boolean>(false);
    const [offRoadUseId, setOffRoadUseId] = useState<any>(null);
    const [offRoadUseHasStep, setOffRoadUseHasStep] = useState<boolean>(false);
    const [offRoadVolConfirmed, setOffRoadVolConfirmed] = useState<boolean>(false);
    const [marineVolConfirmed, setMarineVolConfirmed] = useState<boolean>(false);
    const [confirmState, setConfirmState] = useState<boolean>(false);
    const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
    const [showModalDeleteRoaduse, setShowModalDeleteRoaduse] = useState<boolean>(false);
    const [showModalDeleteOffRoad, setShowModalDeleteOffRoad] = useState<boolean>(false);
    const [showModalDeleteMarine, setShowModalDeleteMarine] = useState<boolean>(false);
    const [roadUseLevels, setRoadUseLevels] = useState({
        general: "",
        gasolineSub: "",
        advancedSub: "",
    });
    const [offRoadLevels, setOffRoadLevels] = useState({
        general: "",
    });
    const [marineLevels, setMarineLevels] = useState({
        general: "",
    });
    const [mandateStatuses, setMandateStatuses] = useState({
        roaduseVolume: ["", ""],
        roaduseAllocation: ["", ""],
        roaduseReport: ["", ""],
        roaduseSteps: ["", "", "", "", ""],
        offroadVolume: ["", ""],
        offroadAllocation: ["", ""],
        offroadReport: ["", ""],
        offroadSteps: ["", "", ""],
        marineVolume: ["", ""],
        marineAllocation: ["", ""],
        marineReport: ["", ""],
        marineSteps: ["", "", ""],
    });

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [
        showConfirmDeliveryModal,
        setShowConfirmDeliveryModal,
    ] = useState<boolean>(false);

    const openErrorModal = (msg: string) => {
        setErrorMessage(msg);
        setShowErrorModal(true);
    };
    const closeErrorModal = () => {
        setErrorMessage("");
        setShowErrorModal(false);
    };

    const openConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(true);
    };

    const closeConfirmDeliveryModal = () => {
        setShowConfirmDeliveryModal(false);
    };

    const openModalDeleteRoaduse = () => {
        setShowModalDeleteRoaduse(true);
    };
    const closeModalDeleteRoaduse = () => {
        setShowModalDeleteRoaduse(false);
    };

    const openModalDeleteOffRoad = () => {
        setShowModalDeleteOffRoad(true);
    };
    const closeModalDeleteOffRoad = () => {
        setShowModalDeleteOffRoad(false);
    };
    const openModalDeleteMarine = () => {
        setShowModalDeleteMarine(true);
    };
    const closeModalDeleteMarine = () => {
        setShowModalDeleteMarine(false);
    };
    const handleRoadUseVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/roadusevolumes`);
        }
    };
    const goToReportOverview = () => {
        if (match) {
            history.push(`/sustainability/reportoverview/`);
        }
    };

    const handleStartAllocation = async () => {
        const response = await resetAllocation();
        dispatch(emptyKey());
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/roaduse/allocation`);
            }
        } else {
            alert("Error occured when reseting allocation");
        }

    };

    // Change the function name later
    const handleContinueAllocation = async () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/roaduse/allocation`);
        }
    };
    const toggleRoaduseMandateState =  (label: string) => {
        const isEnable = label !== "Disabled";
        setIsRoadUseToggleButtonEnabled(isEnable);
        toggleRoaduseMandateStatus(isEnable).then(() => {
            checkDisableConfirmation();
        }).catch(error => {
            console.error("Error updating mandate status:", error);
        });
    };

    const toggleOffroadMandateState =  (label: string) => {
        const isEnable = label !== "Disabled";
        setIsoffRoadToggleButtonEnabled(isEnable);
        toggleOffroadMandateStatus(isEnable).then(() => {
            checkDisableConfirmation();
        }).catch(error => {
            console.error("Error updating mandate status:", error);
        });
        if (isEnable && isMarineToggleButtonEnabled) {
            setIsToggleOffroadMarineModalOpen(true);
        }
    };

    const toggleMarineMandateState =  (label: string) => {
        const isEnable = label !== "Disabled";
        setIsMarineToggleButtonEnabled(isEnable);
        toggleMarineMandateStatus(isEnable).then(() => {
            checkDisableConfirmation();
        }).catch(error => {
            console.error("Error updating mandate status:", error);
        });
        if (isEnable && isOffRoadToggleButtonEnabled) {
            setIsToggleOffroadMarineModalOpen(true);
        }
    };

    const toggleRoaduseJointfulfullmentState =  (label: string) => {
        const isEnable = label !== "Own Fullfillment";
        setIsRoadUseJoint(isEnable);
        toggleRoaduseJointFulfillmentStatus(isEnable);
    };

    const toggleOffroadJointFulfillmentState =  (label: string) => {
        const isEnable = label !== "Own Fullfillment";
        setIsOffRoadJoint(isEnable);
        toggleOffroadJointFulfillmentStatus(isEnable);
    };

    const toggleMarineJointFulfillmentState =  (label: string) => {
        const isEnable = label !== "Own Fullfillment";
        setIsMarineJoint(isEnable);
        toggleMarineJointFulfillmentStatus(isEnable);
    };

    const handleOffRoadReport = async () => {
        const response = await resetAllocationOffroad();
        dispatch(emptyKeyOffroad());
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/offroad/allocation`);
            }
        }
    };
    const handleContinueAllocationOffroad = async () => {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/offroad/allocation`);
            }
    };

    const handleMarineReport = async () => {
        const response = await resetAllocationMarine();
        dispatch(emptyKeyMarine());
        if (response && response.success) {
            if (match) {
                // startAllocation();
                history.push(`/sustainability/mandateoverview/marine/allocation`);
            }
        }
    };
    const handleContinueAllocationMarine = async () => {
        if (match) {
            // startAllocation();
            history.push(`/sustainability/mandateoverview/marine/allocation`);
        }
    };
    const handleOffRoadVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/offroadvolumes`);
        }
    };
    const handleMarineVolume = () => {
        if (match) {
            history.push(`/sustainability/mandateoverview/marinevolumes`);
        }
    };

    // const handleDraftReport = (mandateType:string) => {
    //     if (match) {
    //         history.push(`/sustainability/reports/${mandateType}`);
    //     }
    // };
    //
    // const handleViewDraft = () => {
    //     if (match) {
    //         history.push(`/sustainability/reports`);
    //     }
    // };

    const checkData = async () => {
        const roadUse = await hasRoadData();
        if (roadUse) {
            setRoadUseId(roadUse.id);
            setRoadUseVolConfirmed(roadUse.volume_confirmed);
            setRoadUseHasStep(!!roadUse.last_active_step);
            setIsRoadUseToggleButtonEnabled(roadUse.mandate_enabled);
            setIsRoadUseJoint(roadUse.joint_fulfillment);
            setRoadUseLevels({
                general: parseAndFormat(roadUse.general_mandate, 2),
                gasolineSub: parseAndFormat(roadUse.gasoline_sub_mandate, 2),
                advancedSub: parseAndFormat(roadUse.advanced_sub_mandate, 2),
            });
        }

        const offRoadUse = await hasOffRoadData();
        if (offRoadUse) {
            setOffRoadUseId(offRoadUse.id);
            setOffRoadVolConfirmed(offRoadUse.volume_confirmed);
            setOffRoadUseHasStep(!!offRoadUse.last_active_step);
            setIsoffRoadToggleButtonEnabled(offRoadUse.mandate_enabled);
            setIsOffRoadJoint(offRoadUse.joint_fulfillment);
            setOffRoadLevels({
                general: parseAndFormat(offRoadUse.general_mandate, 2),
            });
        }

        const marine = await hasMarineData();
        if (marine) {
            setMarineDataId(marine.id);
            setMarineVolConfirmed(marine.volume_confirmed);
            setMarineHasStep(!!marine.last_active_step);
            setIsMarineToggleButtonEnabled(marine.mandate_enabled);
            setIsMarineJoint(marine.joint_fulfillment);
            setMarineLevels({
                general: parseAndFormat(marine.general_mandate, 2),
            });
        }

        await checkConfirmationStatus();
        checkDisableConfirmation();
    };

    const isMounted = useRef(true);

    const handleConfirmationStatus = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            const res = await getConfirmState({ taxYear: activePeriod.year });
            if (res === null) {
                openErrorModal(
                    "Failed to confirm delivery. Possible errors: correct reporting year is not selected or other errors."
                );
            } else {
                openErrorModal(
                    "Delivery Confirmed."
                );
            }
            checkData();
        }
    };

    const checkConfirmationStatus = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            // tslint:disable-next-line:no-shadowed-variable
            let confirmState: any;
            confirmState = await checkConfirmState({taxYear: activePeriod.year});
            setConfirmState(confirmState);
        }
    };

    const checkDisableConfirmation = async () => {
        if (activePeriod && activePeriod.year) {
            // @ts-ignore
            // tslint:disable-next-line:no-shadowed-variable
            let disableConfirm: any;
            disableConfirm = await checkDisableConfirm({taxYear: activePeriod.year});
            setDisableConfirm(disableConfirm);
        }
        const statusData = await getMandateStatuses();
        if (statusData) {
            setMandateStatuses({
                roaduseVolume: statusData.roaduseVolume,
                roaduseAllocation: statusData.roaduseAllocation,
                roaduseReport: statusData.roaduseReport,
                roaduseSteps: statusData.roaduseSteps,
                offroadVolume: statusData.offroadVolume,
                offroadAllocation: statusData.offroadAllocation,
                offroadReport: statusData.offroadReport,
                offroadSteps: statusData.offroadSteps,
                marineVolume: statusData.marineVolume,
                marineAllocation: statusData.marineAllocation,
                marineReport: statusData.marineReport,
                marineSteps: statusData.marineSteps,
            });
        }
    };

    useEffect(() => {
        setAreButtonsVisibleRoadUse(isRoadUseToggleButtonEnabled);
        setAreButtonsVisibleOffRoad(isOffRoadToggleButtonEnabled);
        setAreButtonsVisibleMarine(isMarineToggleButtonEnabled);
    }, [isSimulation, isRoadUseToggleButtonEnabled, isOffRoadToggleButtonEnabled, isMarineToggleButtonEnabled]);

    useEffect(() => {
        const params = {
            allocation: isSimulation ? "Simulation" : "Reporting",
            fulfilment: "own",
            // @ts-ignore
            taxYearId: activePeriod.id,
        };

        getAllocationPageData(params);
        checkData();

    }, [isSimulation, activePeriod]);

    useEffect(() => {
        isMounted.current = true;
        if (isMounted.current) {
            checkData();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    const currentYear = new Date().getFullYear();
    // @ts-ignore
    return (
        <div>
            <h1>Bio mandate reporting</h1>
            <div className={classes.cardContainer}>
                <Card className={`${classes.card} ${classes.cardReport}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.toggleCardTitle}>
                            <FormattedMessage id="allocationView.mainView.allocationMode"/>
                        </Typography>

                        <ToggleButton
                            labels={["Reporting", "Simulation"]}
                            defaultLabelIndex={isSimulation ? 1 : 0}
                            handleChange={() => dispatch(toggleSimulation())}
                        />
                    </CardContent>
                </Card>
                <Card className={`${classes.card} ${classes.cardAllocation}`}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h5" className={classes.cardTitle}>
                            <p>Report File and Actions</p>
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={openConfirmDeliveryModal}
                                    disabled={disableConfirm || confirmState}
                                >
                                    {confirmState ? (
                                        <FormattedMessage id="sustainabilityView.reportsView.confirmed" />
                                    ) : (
                                        <FormattedMessage id="loginView.confirm" />
                                    )}{" "}
                                    <FormattedMessage id="sustainabilityView.reportsView.delivery" />
                                </ButtonRounded>

                            </Grid>
                            <Grid item xs={6}>
                                <ButtonRounded
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={goToReportOverview}
                                    className={classes.buttonMargin}
                                    disabled={false}
                                >
                                    Report overview
                                </ButtonRounded>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>

                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Road use mandate for {activePeriod ? activePeriod.year : currentYear - 1}</h1>
                                <p>The road use mandate in {activePeriod ? activePeriod.year : currentYear - 1} is {roadUseLevels.general} %.
                                    In addition to the general mandate level, the road use mandate also contains
                                    separate sub-mandates for gasoline ({roadUseLevels.gasolineSub} %) and minimum
                                    volume of biofuels with advanced sustainability criteria
                                    ({roadUseLevels.advancedSub} %).</p>

                                {areButtonsVisibleRoadUse && (
                                    <Grid container spacing={2}>
                                        <div className={classes.containerArrowProgressbar}>
                                            <div className={classes.wrapper}>
                                                <div className={`${classes.arrowSteps} ${classes.clearfixAfter}`}>
                                                    <div className={`step ${mandateStatuses.roaduseVolume[0]}`}>
                                                        <span>{mandateStatuses.roaduseVolume[1]}</span>
                                                    </div>
                                                    <div className={`step ${mandateStatuses.roaduseAllocation[0]}`}>
                                                        <span>{mandateStatuses.roaduseAllocation[1]}</span>
                                                    </div>
                                                    <div className={`step ${mandateStatuses.roaduseReport[0]}`}>
                                                        <span>{mandateStatuses.roaduseReport[1]}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseVolume}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId !== null && roadUseVolConfirmed}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleRoadUseVolume}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null || (roadUseId && !roadUseVolConfirmed)}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={roadUseId === null || (roadUseId && !roadUseVolConfirmed)}
                                            onClick = {() => openModalDeleteRoaduse()}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleStartAllocation}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null || roadUseHasStep || !roadUseVolConfirmed}
                                        >
                                                Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => setIsRestartRoaduseModalOpen(true)}
                                            className={classes.buttonMargin}
                                            disabled={roadUseId === null || !roadUseHasStep}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleContinueAllocation}
                                            disabled={roadUseId === null || !roadUseHasStep}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>
                                </Grid>
                                )}
                                {showModalDeleteRoaduse && (
                                    <>
                                        <ModalDeleteVolumeInMandate
                                            open={showModalDeleteRoaduse}
                                            handleDelete={async () => {
                                                await deleteRoadUseData(roadUseId);
                                                await resetAllocation();
                                                await toggleRoaduseMandateStatus(true);
                                                // await checkData();
                                                window.location.reload();
                                            }}
                                            onClose={closeModalDeleteRoaduse}
                                        />
                                    </>
                                )}
                                {isRestartRoaduseModalOpen && (
                                    <>
                                        <ModalRestartAllocation
                                            open={isRestartRoaduseModalOpen}
                                            handleRestart={handleConfirmAndRestartRoaduse}
                                            onClose={handleCloseRestartRoaduseModal}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>

                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Enabled", "Disabled"]}
                                    defaultLabelIndex={isRoadUseToggleButtonEnabled ? 0 : 1}
                                    handleChange={toggleRoaduseMandateState}
                                />
                                </div>
                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isRoadUseJoint ? 0 : 1}
                                    handleChange={toggleRoaduseJointfulfullmentState}
                                />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Off-road mandate for {activePeriod ? activePeriod.year : currentYear - 1}</h1>
                                <p>The off-road mandate in {activePeriod ? activePeriod.year : currentYear - 1} is {offRoadLevels.general} %. Fulfillment
                                    of this mandate is only possible with biofuels that have advanced sustainability criteria.</p>

                                {areButtonsVisibleOffRoad && (
                                    <Grid container spacing={2}>
                                        <div className={classes.containerArrowProgressbar}>
                                            <div className={classes.wrapper}>
                                                <div className={`${classes.arrowSteps} ${classes.clearfixAfter}`}>
                                                    <div className={`step ${mandateStatuses.offroadVolume[0]}`}>
                                                        <span>{mandateStatuses.offroadVolume[1]}</span>
                                                    </div>
                                                    <div className={`step ${mandateStatuses.offroadAllocation[0]}`}>
                                                        <span>{mandateStatuses.offroadAllocation[1]}</span>
                                                    </div>
                                                    <div className={`step ${mandateStatuses.offroadReport[0]}`}>
                                                        <span>{mandateStatuses.offroadReport[1]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadVolume}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId !== null && offRoadVolConfirmed}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadVolume}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || (offRoadUseId && !offRoadVolConfirmed)}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => openModalDeleteOffRoad()}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || (offRoadUseId && !offRoadVolConfirmed)}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleOffRoadReport}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || offRoadUseHasStep || !offRoadVolConfirmed}
                                        >
                                            Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => setIsRestartOffroadModalOpen(true)}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || !offRoadUseHasStep}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleContinueAllocationOffroad}
                                            className={classes.buttonMargin}
                                            disabled={offRoadUseId === null || !offRoadUseHasStep}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>

                                </Grid>
                                )}
                                {showModalDeleteOffRoad && (
                                    <>
                                        <ModalDeleteVolumeInMandate
                                            open={showModalDeleteOffRoad}
                                            handleDelete={async () => {
                                                await deleteOffRoadUseData(offRoadUseId, "offroad");
                                                await resetAllocationOffroad();
                                                await toggleOffroadMandateStatus(true);
                                                // await checkData();
                                                window.location.reload();
                                            }}
                                            onClose={closeModalDeleteOffRoad}
                                        />
                                    </>
                                )}
                                {isRestartOffroadModalOpen && (
                                    <>
                                        <ModalRestartAllocation
                                            open={isRestartOffroadModalOpen}
                                            handleRestart={handleConfirmAndRestartOffroad}
                                            onClose={handleCloseRestartOffroadModal}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>
                                <div className={classes.toggleButtonMargin}>
                                    <ToggleButton
                                        labels={["Enabled", "Disabled"]}
                                        defaultLabelIndex={isOffRoadToggleButtonEnabled ? 0 : 1}
                                        handleChange={toggleOffroadMandateState}
                                    />
                                </div>
                                <div className={classes.toggleButtonMargin}>
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isOffRoadJoint ? 0 : 1}
                                    handleChange={toggleOffroadJointFulfillmentState}
                                />
                                </div>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card className={`${classes.card}`}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <h1>Marine mandate for {activePeriod ? activePeriod.year : currentYear - 1}</h1>
                                <p>The marine mandate in {activePeriod ? activePeriod.year : currentYear - 1} is {marineLevels.general} %. Fulfillment
                                    of this mandate is only possible with biofuels that have advanced sustainability criteria.</p>

                                {areButtonsVisibleMarine && (
                                    <Grid container spacing={2}>
                                    <div className={classes.containerArrowProgressbar}>
                                        <div className={classes.wrapper}>
                                            <div className={`${classes.arrowSteps} ${classes.clearfixAfter}`}>
                                                <div className={`step ${mandateStatuses.marineVolume[0]}`}>
                                                    <span>{mandateStatuses.marineVolume[1]}</span>
                                                </div>
                                                <div className={`step ${mandateStatuses.marineAllocation[0]}`}>
                                                    <span>{mandateStatuses.marineAllocation[1]}</span>
                                                </div>
                                                <div className={`step ${mandateStatuses.marineReport[0]}`}>
                                                    <span>{mandateStatuses.marineReport[1]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineVolume}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId !== null && marineVolConfirmed}
                                        >
                                            Add Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineVolume}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null || (marineDataId && !marineVolConfirmed)}
                                        >
                                            Edit Volume
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => openModalDeleteMarine()}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null || (marineDataId && !marineVolConfirmed)}
                                        >
                                            Delete Volumes
                                        </ButtonRounded>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleMarineReport}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null || marineHasStep || !marineVolConfirmed}
                                        >
                                            Start Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => setIsRestartMarineModalOpen(true)}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null || !marineHasStep}
                                        >
                                            Restart Allocation
                                        </ButtonRounded>
                                        <ButtonRounded
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleContinueAllocationMarine}
                                            className={classes.buttonMargin}
                                            disabled={marineDataId === null || !marineHasStep}
                                        >
                                            Continue Allocation
                                        </ButtonRounded>
                                    </Grid>

                                </Grid>
                                )}
                                {showModalDeleteMarine && (
                                    <>
                                        <ModalDeleteVolumeInMandate
                                            open={showModalDeleteMarine}
                                            handleDelete={async () => {
                                                await deleteOffRoadUseData(marineDataId, "marine");
                                                await resetAllocationMarine();
                                                await toggleMarineMandateStatus(true);
                                                // await checkData();
                                                window.location.reload();
                                            }}
                                            onClose={closeModalDeleteMarine}
                                        />
                                    </>
                                )}
                                {isRestartMarineModalOpen && (
                                    <>
                                        <ModalRestartAllocation
                                            open={isRestartMarineModalOpen}
                                            handleRestart={handleConfirmAndRestartMarine}
                                            onClose={handleCloseRestartMarineModal}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid>
                        </Grid>
                            <Grid item xs={3}>
                                <Typography variant="h5" className={classes.toggleCardTitle}>
                                    <p>Mandate settings</p>
                                </Typography>
                                <div className={classes.toggleButtonMargin} >
                                    <ToggleButton
                                        labels={["Enabled", "Disabled"]}
                                        defaultLabelIndex={isMarineToggleButtonEnabled ? 0 : 1}
                                        handleChange={toggleMarineMandateState}
                                    />
                                </div>
                                <div className={classes.toggleButtonMargin} >
                                <ToggleButton
                                    labels={["Joint Fullfillment", "Own Fullfillment"]}
                                    defaultLabelIndex={isMarineJoint ? 0 : 1}
                                    handleChange={toggleMarineJointFulfillmentState}
                                />
                                </div>

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                {isToggleOffroadMarineModalOpen && (
                    <>
                        <ModalToggleMandate
                            open={isToggleOffroadMarineModalOpen}
                            onClose={handleCloseToggleOffroadMarineModal}
                        />
                    </>
                )}

            <ModalInfo
                text={errorMessage}
                open={showErrorModal}
                onClose={closeErrorModal}
            />
            <ModalConfirmDelivery
                open={showConfirmDeliveryModal}
                onClose={closeConfirmDeliveryModal}
                onConfirm={() => {
                    closeConfirmDeliveryModal();
                    handleConfirmationStatus();
                }}
            />
        </div>
    );
};
export default MandateOverviewContainer;
