/**
 * Get users list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const hasMarineData = async () => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/check_data_marine`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data) {
          return data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getMarineData = async () => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/add_marine_volume`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getMarineCreateData = async (params: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/add_marine_volume`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getMarineEditData = async (params: any) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/add_marine_volume`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const deleteMarineData = async (id: any) => {
  const token = getToken();
  const params = {
    id,
  };
  if (token && isAdmin()) {
    try {
      // @ts-ignore
      const response = await fetch(`${BASE_URL}/api/add_marine_volume`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const toggleMarineMandateStatus = async (mandate_enabled: boolean) => {
  const token = getToken();
  const params = {
    mandate_enabled,
  };
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/toggle_marine_mandate_settings`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const toggleMarineJointFulfillmentStatus = async (joint_fulfillment: boolean) => {
  const token = getToken();
  const params = {
    joint_fulfillment,
  };
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/construction_diesel_volumes/toggle_marine_mandate_settings`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};
export default getMarineData;
