import React from "react";
import {Switch, Route, Redirect, useRouteMatch} from "react-router-dom";
import FilesView from "../../../containers/sustainability/FilesContainer";
import CustomersContainer from "../../../containers/sustainability/CustomerContainer";
import StorageContainer from "../../../containers/sustainability/StorageContainer";
import OutgoingTransContainer from "../../../containers/sustainability/storage/OutgoingTransContainer";
import AllocationView from "./AllocationView";
import ReportsContainer from "../../../containers/sustainability/ReportsContainer";
import OutgoingTransHBLContainer from "../../../containers/sustainability/storage/OutgoingTransHBLContainer";
import BiofuelVolumeTransferContainer from "../../../containers/sustainability/storage/BiofuelVolumeTransferContainer";
import BiofuelReconciliationContainer from "../../../containers/sustainability/storage/BiofuelReconciliationContainer";
import BiofuelReconciliationVolumeContainer
    from "../../../containers/sustainability/storage/BiofuelReconciliationVolumeContainer";
import MandateOverviewContainer from "../../../containers/sustainability/mandateOverView/MandateOverviewContainer";
import RoadUseVolumeContainer from "../../../containers/sustainability/mandateOverView/RoadUseVolumeContainer";
import OffRoadVolumeContainer from "../../../containers/sustainability/mandateOverView/OffRoadVolumeContainer";
import MarineVolumeContainer from "../../../containers/sustainability/mandateOverView/MarineVolumeContainer";
import AllocationContainer from "../../../containers/sustainability/AllocationContainer";
import RoadUseContainer from "../../../containers/sustainability/allocation/roadUse/Container";
import OffRoadContainer from "../../../containers/sustainability/allocation/offRoad/Container";
import MarineContainer from "../../../containers/sustainability/allocation/marine/Container";
import RoadUseAllocationContainer from "../../../containers/sustainability/RoadUseAllocationContainer";
import ReportOverviewContainer from "../../../containers/sustainability/reportOverview/ReportOverviewContainer";
import ReportsContainerOffroad from "../../../containers/sustainability/ReportsContainerOffroad";
import ReportsContainerMarine from "../../../containers/sustainability/ReportsContainerMarine";

const SustainabilityView: React.FC = () => {
    const match = useRouteMatch();

    if (match) {
        return (
            <Switch>
                <Route path={`${match.path}/files`} component={FilesView}></Route>
                <Route
                    exact
                    path={`${match.path}/storage`}
                    component={StorageContainer}
                ></Route>
                <Route
                    path={`${match.path}/storage/outgoing-transaction-hbl`}
                    component={OutgoingTransHBLContainer}
                ></Route>
                <Route
                    path={`${match.path}/storage/outgoing-transaction`}
                    component={OutgoingTransContainer}
                ></Route>

                <Route
                    path={`${match.path}/storage/biofuel-volume-transaction`}
                    component={BiofuelVolumeTransferContainer}
                ></Route>
                <Route
                    path={`${match.path}/storage/biofuel-reconciliation`}
                    component={BiofuelReconciliationContainer}
                ></Route>
                <Route
                    path={`${match.path}/storage/reconcile-volume/:terminal/:biofuel/:avail_volume/:reconcile_date`}
                    component={BiofuelReconciliationVolumeContainer}
                ></Route>
                <Route
                    path={`${match.path}/customers`}
                    component={CustomersContainer}
                ></Route>
                <Route
                    path={`${match.path}/mandateoverview/roadusevolumes`}
                    component={RoadUseVolumeContainer}
                ></Route>
                <Route
                    path={`${match.path}/mandateoverview/offroadvolumes`}
                    component={OffRoadVolumeContainer}
                ></Route>
                <Route
                    path={`${match.path}/mandateoverview/marinevolumes`}
                    component={MarineVolumeContainer}
                ></Route>

                <Route
                    path={`${match.path}/mandateoverview/roaduse/allocation`}
                    component={RoadUseContainer}
                ></Route>
                <Route
                    path={`${match.path}/mandateoverview/offroad/allocation`}
                    component={OffRoadContainer}
                ></Route>
                <Route
                    path={`${match.path}/mandateoverview/marine/allocation`}
                    component={MarineContainer}
                ></Route>
                <Route
                    path={`${match.path}/mandateoverview`}
                    component={MandateOverviewContainer}
                ></Route>
                <Route
                    path={`${match.path}/reportoverview`}
                    component={ReportOverviewContainer}
                ></Route>
                <Route
                    path={`${match.path}/reports/roaduse`}
                    component={ReportsContainer}
                ></Route>
                <Route
                    path={`${match.path}/reports/offroad`}
                    component={ReportsContainerOffroad}
                ></Route>
                <Route
                    path={`${match.path}/reports/marine`}
                    component={ReportsContainerMarine}
                ></Route>
            </Switch>
        );
    } else {
        return null;
    }
};

export default SustainabilityView;
