/**
 * A general purpose Table component that displays a list of files
 * with some actions associated with each item
 */

import React, { useState } from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FormattedMessage } from "react-intl";
import SortableArrow from "./SortableArrow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import parseData from "../../components/texts/parseData";
import parseAndFormat from "../texts/parseAndFormat";
import parseToFloat from "../texts/parseToFloat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    content: {
      display: "flex",
    },
    noData: {
      width: "100%",
      height: "80px",
      border: `1px solid ${theme.palette.grey[200]}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataText: {
      color: theme.palette.grey[300],
    },
    sideGradient: {
      top: "0px",
      width: "34px",
      marginLeft: "-24px",
      background:
        "linear-gradient(90deg, rgb(255, 255, 255, 1) 30%, rgb(255, 255, 255, 0))",
      zIndex: 100,
    },
    sideButtons: {
      top: "0px",
      width: "80px",
      boxShadow: "-5px -5px 5px 0 rgba(30, 30, 30, 0.05)",
      color: theme.palette.primary.main,
      backgroundColor: "#fdefd9",
      textAlign: "center",
      zIndex: 1,
    },
    sideButtonHead: { height: "75px", width: "70px" },
    sideButton: { height: "35px", width: "70px" },
    menu: {
      height: "300px",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1, 2),
      overflowY: "scroll",
    },
    menuTitle: {
      marginBottom: theme.spacing(2),
    },
    menuLabel: { color: theme.palette.primary.main },
    menuLabelSelected: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    table: {
      minWidth: "550px",
    },
    tableWrapperSmart: {
      marginLeft: "-28px",
      paddingLeft: "19px",
      marginRight: "-2px",
      width: "100%",
      overflowX: "scroll",
    },
    tableWrapper: {
      width: "100%",
    },
    columnTitle: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    textField: {
      margin: 0,
    },
    textFieldColored: {
      margin: 0,
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    toggleIcon: {
      borderRadius: "100%",
      margin: -5,
      padding: 0,
      marginLeft: 0.5,
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  })
);

const StyledHeadMenu = withStyles({})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledRowMenu = withStyles({})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: { "& .MuiListItemIcon-root": { minWidth: theme.spacing(4) } },
}))(MenuItem);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(1) },
    head: {
      whiteSpace: "break-spaces",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.grey[300]}`,
      fontWeight: 500,
    },
    body: {
      whiteSpace: "nowrap",
      fontSize: 12,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) { return order; }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

interface headCell {
  name?: string | React.ReactElement;
  idFormattedName?: string;
  id: string;
  alignRight?: boolean;
  size?: string;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: headCell[];
  filteredHead: any;
}

function EnhancedTableHead(
  props: EnhancedTableProps & { headHeight: string; backgroundColor: string }
) {
  const {
    backgroundColor,
    headHeight,
    classes,
    order,
    orderBy,
    headCells,
    onRequestSort,
    filteredHead,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) =>
          filteredHead[headCell.id] ? null : (
            <StyledTableCell
              key={headCell.id + "headCell" + index}
              sortDirection={orderBy === headCell.id ? order : false}
              align={headCell.alignRight ? "right" : "left"}
              style={{
                backgroundColor,
                height: headHeight,
                width: headCell.size,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
                IconComponent={props => (
                  <SortableArrow
                    active={orderBy === headCell.id}
                    order={order}
                    {...props}
                  ></SortableArrow>
                )}
              >
                <Typography className={classes.columnTitle}>
                  {headCell.idFormattedName ? (
                    <FormattedMessage id={headCell.idFormattedName} />
                  ) : (
                    headCell.name
                  )}
                </Typography>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableRow(props: {
  row: any;
  headCells: headCell[];
  classes: ReturnType<typeof useStyles>;
  filteredHead: any;
  smartProps: any;
  editableProps: any;
  open: any;
  setOpen: any;
  backgroundColor?: any;
}) {
  const {
    row,
    headCells,
    classes,
    filteredHead,
    smartProps,
    editableProps,
    open,
    setOpen,
    backgroundColor,
  } = props;
  const [showAlert, setShowAlert] = useState(false);
  return (
    <StyledTableRow
      style={{
        backgroundColor,
      }}
    >
      {headCells.map((headCell, i) =>
        filteredHead[headCell.id] ? null : (
          <StyledTableCell
            align={headCell.alignRight ? "right" : "left"}
            key={headCell.id + row.name + i}
            style={{
              whiteSpace: smartProps ? "nowrap" : "break-spaces",
            }}
          >
            {i === 0 && row.child ? (
              <KeyboardArrowDownIcon
                color="primary"
                className={classes.toggleIcon}
                style={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  color: open ? "#ffac12" : "#fff",
                  backgroundColor: open ? "#fff" : "#ffac12",
                }}
                fontSize="small"
                onClick={() => setOpen(!open)}
              />
            ) : editableProps && headCell.id === editableProps.id ? (
              <TextField
                className={
                  editableProps.getValue(row) != 0
                    ? classes.textFieldColored
                    : classes.textField
                }
                value={editableProps.getValue(row) != 0 ? editableProps.getValue(row) : ""}
                onChange={e => editableProps.onChange(e, row, i)}
                onBlur={e => editableProps.onBlur(e, row, i)}
                variant="outlined"
                placeholder="E.g. 100 000,00"
                type="text"
                inputProps={{
                  style: {
                    textAlign: "right",
                    fontWeight:
                      editableProps.getValue(row) != 0 ? "bold" : "normal",
                    backgroundColor:
                      editableProps.getValue(row) != 0 ? "#fdefd9" : "#fff",
                  },
                }}
                fullWidth
              />
            ) : headCell.id === "reduction_in_climate_emission" ? (
                parseAndFormat(row[headCell.id], 2) + " %"
            ) : headCell.id === "available_quantity" ? (
                parseToFloat(row['allocated_quantity']) > parseToFloat(row['available_quantity']) ? (
                    <span style={{fontWeight: 'bold', color: 'red'}}>
                    Volume exceeded
                  </span>
                ) : parseAndFormat(parseToFloat(row['available_quantity']), 2)
            ) : (
                parseAndFormat(row[headCell.id], 2)
            )}
          </StyledTableCell>
        )
      )}
    </StyledTableRow>
  );
}

function ExtendableTableRow(props: {
  index: number;
  row: any;
  headCells: headCell[];
  classes: ReturnType<typeof useStyles>;
  filteredHead: any;
  smartProps: any;
  editableProps: any;
  extendedRows: any;
  handleSetExtendedRow: any;
}) {
  const {
    index,
    row,
    headCells,
    classes,
    filteredHead,
    smartProps,
    editableProps,
    extendedRows,
    handleSetExtendedRow,
  } = props;

  let open = extendedRows[index];

  return (
    <React.Fragment>
      <EnhancedTableRow
        key={index + "_enhancedTableRow"}
        row={row}
        headCells={headCells}
        classes={classes}
        filteredHead={filteredHead}
        smartProps={smartProps}
        editableProps={editableProps}
        open={open}
        setOpen={() => handleSetExtendedRow(index)}
        backgroundColor={open ? "#ffd588" : null}
      />
      {open && (
        <React.Fragment>
          {row.child &&
            row.child.map((sub_row: any, i: number) => (
              <EnhancedTableRow
                key={index + "_" + row.id + "sub_row" + i}
                row={sub_row}
                headCells={headCells}
                classes={classes}
                filteredHead={filteredHead}
                smartProps={smartProps}
                editableProps={editableProps}
                open={open}
                setOpen={() => handleSetExtendedRow(index)}
                backgroundColor={open ? "#fdefd9" : null}
              />
            ))}
          <TableRow></TableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

interface ISmartTableProps {
  headCells: headCell[];
  rows: any;
  smartProps?: any;
  editableProps?: any;
  whiteHeader?: boolean;
  verticalOverflow?: boolean;
  aggregate?: {
    removeZeroBalanced?: boolean;
    showZeroBalanced?: boolean;
    required: string[];
    addUp: string[];
  };
}

const SmartTable: React.FC<ISmartTableProps> = ({
  headCells,
  smartProps,
  editableProps,
  whiteHeader,
  verticalOverflow,
  rows,
  aggregate,
}) => {
  const classes = useStyles();
  const [extendedRows, setExtendedRows] = React.useState<{
    [index: number]: any;
  }>({});
  const [filteredHead, setFilteredHead] = React.useState(
    smartProps
      ? {
        ...smartProps.initFilteredHead,
      }
      : {}
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElRow, setAnchorElRow] = React.useState<null | HTMLElement>(
    null
  );
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    verticalOverflow ? 1000000 : 10
  );

  const handleSetExtendedRow = (index: number): void => {
    if (!extendedRows[index]) {
      setExtendedRows({ ...extendedRows, [index]: true });
    }
    else { setExtendedRows({ ...extendedRows, [index]: null }); }
  };

  const handleClickHead = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickRow = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    smartProps.selectEntry(row);
    setAnchorElRow(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElRow(null);
  };

  const handleChangeFilter = (name: string) => {
    setFilteredHead({ ...filteredHead, [name]: !filteredHead[name] });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const aggregateConditions = (aggregate: string[], item1: any, item2: any) => {
    if (!item1 || !item2) { return false; }
    for (let index = 0; index < aggregate.length; index++) {
      if (item1[aggregate[index]] !== item2[aggregate[index]]) { return false; }
    }
    return true;
  };

  const getAggregatedRow = (
    rows: any,
    aggregate: { required: string[]; addUp: string[] }
  ): { [x: string]: any } => {
    let aggregatedRow: { [x: string]: any } = {};
    aggregate.required.map(attribute => {
      aggregatedRow[attribute] = rows[0][attribute];
    });
    aggregate.addUp.map(attribute => {
      let total = 0;
      rows.map((row: any) => (total = total + row[attribute]));
      aggregatedRow[attribute] = total;
    });
    return aggregatedRow;
  };

  let newRows = [];
  if (aggregate && aggregate.required.length > 0 && rows) {
    let copyRows = [...rows];
    for (let index = 0; index < copyRows.length; index++) {
      if (!copyRows[index].filteredOut) {
        let groupedRows = copyRows.filter((row: any, i: number) => {
          if (aggregateConditions(aggregate.required, copyRows[index], row)) {
            copyRows[i] = { ...row, filteredOut: true };
            return true;
          }
          return false;
        });
        let newRow: { [x: string]: any } = {
          ...getAggregatedRow(groupedRows, aggregate),
          child: groupedRows,
        };
        if (aggregate.removeZeroBalanced && newRow.quantity == 0) {
        } else if (aggregate.showZeroBalanced && newRow.quantity != 0) {
        } else { newRows.push(newRow); }
      }
    }
  } else { newRows = rows; }

  if (newRows === null || (Array.isArray(newRows) && newRows.length === 0)) {
    return (
      <div className={classes.noData}>
        <Typography variant="body1" className={classes.noDataText}>
          No data
        </Typography>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {smartProps && <div className={classes.sideGradient}></div>}
          <div
            className={
              smartProps ? classes.tableWrapperSmart : classes.tableWrapper
            }
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                backgroundColor={
                  smartProps || whiteHeader
                    ? "white"
                    : "rgba(255, 172, 18, 0.2)"
                }
                headHeight={smartProps ? "75px" : "30px"}
                classes={classes}
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                filteredHead={filteredHead}
                onRequestSort={handleRequestSort}
              />

              {newRows.length === 0 ? null : (
                <TableBody>
                  {stableSort(newRows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <ExtendableTableRow
                          key={index + "_" + row.id}
                          index={index}
                          row={row}
                          headCells={headCells}
                          classes={classes}
                          filteredHead={filteredHead}
                          smartProps={smartProps}
                          editableProps={editableProps}
                          extendedRows={extendedRows}
                          handleSetExtendedRow={handleSetExtendedRow}
                        />
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </div>
          {smartProps ? (
            <div className={classes.sideButtons}>
              <IconButton
                className={classes.sideButtonHead}
                onClick={handleClickHead}
              >
                <MoreHorizIcon />
              </IconButton>
              {stableSort(newRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  if (row.child) {
                    let buttons = null;
                    if (extendedRows[index]) {
                      buttons = (
                        <React.Fragment>
                          <IconButton
                            key={"iconButtonEmptyExtended" + index}
                            className={classes.sideButton}
                          ></IconButton>
                          {Array.isArray(row.child) &&
                            row.child.map((sub_row: any, i: number) => (
                              <IconButton
                                key={index + "iconButtonChild" + i}
                                className={classes.sideButton}
                                onClick={e => handleClickRow(e, sub_row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            ))}
                        </React.Fragment>
                      );
                    }
                    else {
                      buttons = (
                        <IconButton
                          key={"iconButtonEmpty" + index}
                          className={classes.sideButton}
                        ></IconButton>
                      );
                    }
                    return buttons;
                  } else {
                    return (
                      <IconButton
                        key={"iconButton" + index}
                        className={classes.sideButton}
                        onClick={e => handleClickRow(e, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    );
                  }
                })}
              <StyledHeadMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div className={classes.menu}>
                  <Typography className={classes.menuTitle}>
                    Manage Columns:
                  </Typography>
                  {headCells.map((head, index) => (
                    <FormControlLabel
                      key={head.id + "MenuItem" + index}
                      checked={!filteredHead[head.id]}
                      value={head.id}
                      control={<Radio color="primary" />}
                      label={
                        <Typography
                          className={
                            filteredHead[head.id]
                              ? classes.menuLabel
                              : classes.menuLabelSelected
                          }
                        >
                          {head.idFormattedName ? (
                            <FormattedMessage id={head.idFormattedName} />
                          ) : (
                            head.name
                          )}
                        </Typography>
                      }
                      onClick={e => handleChangeFilter(head.id)}
                    />
                  ))}
                </div>
              </StyledHeadMenu>
              <StyledRowMenu
                id="simple-menu"
                anchorEl={anchorElRow}
                keepMounted
                open={Boolean(anchorElRow)}
                onClose={handleClose}
              >
                <MenuList>
                  <StyledMenuItem
                    onClick={row => {
                      smartProps.editEntry(row);
                      handleClose();
                    }}
                    dense
                    disabled={smartProps.disabledActions}
                  >
                    <ListItemIcon>
                      <EditOutlinedIcon fontSize="default" />
                    </ListItemIcon>
                    <Typography variant="body2" color="primary">
                      Edit
                    </Typography>
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={row => {
                      smartProps.deleteEntry(row);
                      handleClose();
                    }}
                    dense
                    disabled={smartProps.disabledActions}
                  >
                    <ListItemIcon>
                      <DeleteOutlinedIcon fontSize="default" color="error" />
                    </ListItemIcon>
                    <Typography variant="body2" color="error">
                      Delete
                    </Typography>
                  </StyledMenuItem>
                </MenuList>
              </StyledRowMenu>
            </div>
          ) : null}
        </div>
        {!verticalOverflow && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={newRows ? newRows.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
};

export default SmartTable;
