import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { TextField } from "final-form-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import ButtonRounded from "../../../../components/ButtonRounded";
import makeValidate from "../../../../components/form/makeValidate";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
import ModalInfo from "../../../../components/modal/ModalInfo";
import useModal from "../../../../components/modal/useModal";
import SmartSelect from "../../../../components/SmartSelect";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ToggleButton from "../../../../components/ToggleButton";
import { getAppVariables } from "../../../../reducers/settings/app_variables";
import { IAppVariables } from "../../../../types/ApiInterfaces";
import EditableTable from "./EditableTable";
import ModalSaveHblTransaction from "./ModalSaveHblTransaction";
import { getHblBiotemplates } from "../../../../api/sustainability/storage/outgoing-transaction/getBiotemplates";
import parseAndFormat from "../../../../components/texts/parseAndFormat";
import parseToFloat from "../../../../components/texts/parseToFloat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    btn: {
      width: "170px",
      padding: "13px 0",
      lineHeight: "14px",
      "&:not(:first-child)": {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        width: "130px",
        padding: "12px 0 9px 0",
        "&:not(:first-child)": {
          marginLeft: "10px",
        },
      },
    },
    toggleContainer: {
      marginTop: theme.spacing(3),
    },
    topCard: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: "-10px 10px 20px 0 rgba(30, 30, 30, 0.05)",
      padding: theme.spacing(2),
    },
    cardContent: {
      "&.MuiCardContent-root:last-child": {
        paddingBottom: theme.spacing(2),
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1, 1.5),
      },
    },
    cardTable: {
      padding: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    fields: {
      position: "relative",
      "& .MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        bottom: theme.spacing(-1.6),
        whiteSpace: "nowrap",
      },
      "& .MuiFormHelperText-contained": {
        margin: 0,
      },
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface Props {
  app_variables: IAppVariables;
}

const OutgoingTransHBLView: React.FC<Props> = ({ app_variables }) => {
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [volume, setVolume] = useState<any>(null);
  const [headData, setHeadData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [editedTableData, setEditedTableData] = useState<any>(null);
  const [infoMessage, SetInfoMessage] = useState("");
  const [serialNumber, setSerialNumber] = useState<any>(null);

  const { openModalId, toggle } = useModal();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const openModal = (id: string, text: string) => {
    if (id === "info") { SetInfoMessage(text); }
    toggle(id);
  };

  const closeModal = () => {
    SetInfoMessage("");
    toggle();
  };

  const getCustomerList = () => {
    const customerList = app_variables.res_customer.values;
    app_variables.customer.values.forEach(
      (customer: any) =>
        !customerList.includes(customer) && customerList.push(customer)
    );
    return customerList;
  };

  const openSaveModal = () => {
    triggerTableSubmit();

  };
  const closeSaveModal = () => {
    setEditedTableData(null);
    setShowSaveModal(false);
  };

  const triggerTableSubmit = () => {
    if (document) {
      const outgoingTableForm = document.getElementById(
        "storage_outgoing_table_form"
      );
      if (outgoingTableForm) {
        outgoingTableForm.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    }
  };

  const validationSchema = yup.object({
    volume: yup
      .number()
      .max(99999999999999)
      .typeError("Please enter a number.")
      .required(),
    biofuel_type: yup.string().required(),
    storage_terminal: yup.string().required(),
    delivery_period: yup.string().min(3).required(),
    storage_date: yup.string().required(),
  });

  const handleChooseProducts = async (values: any) => {
    setHeadData({ ...values });
    setVolume(values.volume);
    const searchValues = {
      customer_name: "Preem AB",
      volume: parseToFloat(values.volume),
      biofuel_type: values.biofuel_type,
      storage_terminal: values.storage_terminal,
      delivery_period: values.delivery_period,
      storage_date: moment(values.storage_date).format("YYYY-MM-DD"),
    };
    const res = await getHblBiotemplates(searchValues);
    if (res) {
      setTableData(res.biotemplates);
      setSerialNumber(res.serial_number);
    }
  };

  const validateTableData = (values: any) => {
    console.log(values);
  };

  const onFormChange = (formState: any) => {
    const data: any = formState.values;
    const vol = data.volume == null ? data.volume : data.volume.replace(/[^0-9.,]/g, "").replace(/,/g, ".");
    data.volume = parseAndFormat(vol, 0, true);
    setVolume(parseToFloat(data.volume));
    setHeadData(data);
  };

  const handleTableData = (values: any) => {
    if (values && Array.isArray(values.biotemplates)) {
      const filteredValues = values.biotemplates.filter(
        (data: any) => data.allocated_quantity
      );

      if (filteredValues.length > 0) {
        let total = 0;
        for (let i = 0; i <
          filteredValues.length; i += 1) {
          total += parseToFloat(filteredValues[i].allocated_quantity);
          filteredValues[i].serial_number = parseInt(serialNumber) + i;
        }
        if (total !== parseToFloat(volume)) {
          openModal(
            "info",
            "Allocated quantity must be matched with given volume"
          );
          return false;
        }

        const bioValues = filteredValues.map((data: any) => ({
          id: data.id,
          serialNumber: data.serial_number,
          quantity: data.allocated_quantity,
          pos_date: moment(new Date()).format("YYYY-MM-DD"),
          shipment_sale_nomination: data.shipment_sale_nomination,
          b_l_date: data.b_l_date,
          vessel: data.vessel,
          storage_terminal: data.storage_terminal,
          biofuel_type: data.biofuel_type,
          raw_material: data.raw_material,
          raw_material_origin: data.raw_material_origin,
          certificate: data.certificate,
          site_start: data.site_start,
          eec: data.eec,
          el: data.el,
          ep: data.ep,
          etd: data.etd,
          eu: data.eu,
          esca: data.esca,
          eccs: data.eccs,
          eccr: data.eccr,
          eee: data.eee,
          etot: data.etot,
          reduction_in_climate_emission: data.reduction_in_climate_emission,
        }));

        if (bioValues.length > 0) {
          setEditedTableData(bioValues);
        }
        setShowSaveModal(true);
      } else {
        openModal(
          "info",
          "Please allocated quantity before submit"
        );
      }
    }
  };

  const haveTableData = tableData !== null;
  const [initialValues] = useState(
      headData || {
        customer_name: "Preem AB",
        volume: null,
        biofuel_type: "",
        storage_terminal: "",
        delivery_period: "",
        storage_date: new Date(),
        status: "completed",
      }
  );
  useEffect(() => {
    let isMounted = true;
    if (isMounted) { // Only update the state if the component is still mounted
      dispatch(getAppVariables());
    }
    return () => { // Add a cleanup function to cancel any ongoing tasks
      isMounted = false;
    };
  }, []);
  return (
    <div className={classes.root}>
      <Form
        onSubmit={handleChooseProducts}
        validate={values => makeValidate({...values, volume: parseToFloat(values.volume) }, validationSchema)}
        initialValues={initialValues}
      >

        {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
          // eslint-disable-next-line
          useEffect(() => {
            const unsubscribe = form.subscribe(onFormChange, { values: true });
            return unsubscribe;

          }, [form]);
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h2" className={classes.h2}>
                    Add HBL Outgoing Transaction
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonRounded
                    variant="contained"
                    color="secondary"
                    className={classes.btn}
                    onClick={() => history.goBack()}
                  >
                    cancel
                  </ButtonRounded>
                  <ButtonRounded
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onClick={openSaveModal}
                    disabled={!canSubmit}
                  >
                    save
                  </ButtonRounded>
                </Grid>
              </Grid>

              <div className={classes.topCard}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className={classes.cardContent}
                >
                  <Grid item xs className={classes.toggleContainer}>
                    <ToggleButton
                      labels={["Completed", "Blocked"]}
                      size="small"
                      handleChange={() => { }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      Total Volume (L) <RequiredAsterisk />
                    </Typography>
                    <Field
                      component={TextField}
                      name="volume"
                      variant="outlined"
                      placeholder="0,00"
                      fullWidth
                      className={classes.fields}
                      disabled={haveTableData}
                    />
                  </Grid>

                  <Grid item xs>
                    <Typography variant="body2">
                      Biofuel Type <RequiredAsterisk />
                    </Typography>
                    <Field name="biofuel_type">
                      {props => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"biofuel_type"}
                          disabled={haveTableData}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs>
                    <Typography variant="body2">
                      Storage Terminal <RequiredAsterisk />
                    </Typography>
                    <Field name="storage_terminal">
                      {props => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"storage_terminals"}
                          disabled={haveTableData}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs>
                    <Typography variant="body2">
                      Delivery Period <RequiredAsterisk />
                    </Typography>
                    <Field
                      component={TextField}
                      name="delivery_period"
                      variant="outlined"
                      placeholder="e.g. Q1 2019"
                      fullWidth
                      className={classes.fields}
                      disabled={haveTableData}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      Storage Date <RequiredAsterisk />
                    </Typography>
                    <Field name="storage_date">
                      {({ input, meta }) => (
                        <KeyboardDatePicker
                          {...input}
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          className={classes.fields}
                          disabled={haveTableData}
                          placeholder={moment().format("YYYY-MM-DD")}
                          value={input.value ? input.value : null}
                          onChange={(value: any) => {
                            input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </div>

              <Card>
                <CardContent className={classes.cardTable}>
                  <EditableTable
                    data={tableData ? tableData : null}
                    volume={volume}
                    disabledChooseProducts={
                      submitting || pristine || hasValidationErrors
                    }
                    handleChooseProducts={handleSubmit}
                    onSubmit={handleTableData}
                    canSubmit={canSubmit}
                    setCanSubmit={setCanSubmit}
                  />
                </CardContent>
              </Card>
            </form>
        ); }}
      </Form>
      {headData && (
        <ModalSaveHblTransaction
          data={editedTableData}
          generalData={headData}
          open={showSaveModal}
          onClose={closeSaveModal}
        />
      )}
      <ModalInfo
        text={infoMessage}
        open={openModalId === "info"}
        onClose={closeModal}
      />
    </div>
  );
};

export default OutgoingTransHBLView;
