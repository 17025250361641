import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ButtonRounded from "../../components/ButtonRounded";
import ModalContainer from "../../components/ModalContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {},
        container: {
            padding: theme.spacing(2),
        },
        btnContainer: {
            padding: theme.spacing(2, 0),
        },
        btnCancel: {},
    })
);

interface ModalRestartAllocationProps {
    open: boolean;
    onClose: () => void;
    handleRestart: () => void;
}

const ModalRestartAllocation: React.FC<ModalRestartAllocationProps> = ({
   open,
   onClose,
   handleRestart,
}) => {
    const [isError, setIsError] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            title="Restart allocation"
        >
            <div className={classes.modalContent}>
                <Typography variant="body1" gutterBottom>
                    Are you sure you want to restart the allocation? This will restart all your progress on this allocation.
                </Typography>
                <Grid
                    container
                    alignItems="center"
                    direction="column"
                    spacing={2}
                    className={classes.container}
                >
                </Grid>
                <Grid container spacing={2} className={classes.btnContainer}>
                    <Grid item xs={6}>
                        <ButtonRounded
                            fullWidth
                            variant="outlined"
                            onClick={onClose}
                            color="secondary"
                        >
                            Cancel
                        </ButtonRounded>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonRounded
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleRestart}
                            disabled={isError}
                        >
                            Restart
                        </ButtonRounded>
                    </Grid>
                </Grid>
                {isError && (
                    <Typography align="center" variant="body2" color="error">
                        Failed to restart the allocation.
                    </Typography>
                )}
            </div>
        </ModalContainer>
    );
};

export default ModalRestartAllocation;
