import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {useHistory, Link } from "react-router-dom";
import parseAndFormat from "../../../../components/texts/parseAndFormat";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      color: "red",
      textAlign: "center",
      margin: "0 0 10px 0",
    },
    tableContainer: {
      maxHeight: "450px",
      overflowY: "auto", // "scroll",
      "& .MuiOutlinedInput-input": {
        ...theme.typography.caption,
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.caption,
      },
      "& .MuiTableCell-head": {
        ...theme.typography.caption,
        fontWeight: "bold",
        color: theme.palette.common.black
      },
    },
      cardContent: {
          "&.MuiCardContent-root:last-child": {
              paddingBottom: theme.spacing(2),
          },
          "& .MuiOutlinedInput-input": {
              padding: theme.spacing(1, 1.5),
          },
      },
      cardTable: {
          padding: theme.spacing(3),
          paddingRight: theme.spacing(2),
      },
    emptyCell: {
      backgroundColor: theme.palette.common.white,
      height: "350px",
      textAlign: "center",
    },
    btnChooseProduct: {
      minWidth: "170px",
    },
  })
);

const tableHeadings = [
  "Biofuel Type",
  "Available Volume",
  "Latest reconciliation",
  "",
];

interface Props {
  data: any[];
  terminal: string;
}

const ReconciliationList: React.FC<Props> = ({
  data,
  terminal
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleBiofuelReconciliationVolume = (
        terminal: string,
        fuelType: string,
        volume: number,
        reconcile_date: string
    ) => {

        const encodedTerminal = encodeURIComponent(terminal);
        const encodedFuelType = encodeURIComponent(fuelType);
        const encodedVolume = encodeURIComponent(volume);
        const reconcialiation_date = reconcile_date ?
            encodeURIComponent(moment(reconcile_date).format("YYYY-MM-DD"))
            : encodeURIComponent("No reconciliation transactions registered");
        history.push(`/sustainability/storage/reconcile-volume/${encodedTerminal}/${encodedFuelType}/${encodedVolume}/${reconcialiation_date}`);
    };

  return (
    <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeadings.map((heading, hidx) => (
                <TableCell key={hidx}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {Object.keys(data).map((fuelType, index) => (
                  <TableRow key={index}>
                      <TableCell style={{ width: "20%" }}>{fuelType}</TableCell>
                      <TableCell style={{ width: "20%" }}>{parseAndFormat(parseFloat(data[fuelType].available_volume), 2)}</TableCell>
                      <TableCell style={{ width: "20%" }}>
                          {data[fuelType].latest_reconciliation ? (
                              moment(data[fuelType].latest_reconciliation).format("D MMMM YYYY")
                          ) : (
                              <span>No reconciliation transactions registered</span>
                          )}
                      </TableCell>
                      <TableCell style={{ width: "40%" }}>
                          <ButtonRounded
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={() => handleBiofuelReconciliationVolume(
                                  terminal,
                                  fuelType,
                                  parseFloat(data[fuelType].available_volume),
                                  data[fuelType].latest_reconciliation
                              )}
                          >
                              Reconcile {fuelType} Volume
                          </ButtonRounded>
                      </TableCell>
                  </TableRow>
              ))}
          </TableBody>
        </Table>
    </div>
  );
};

export default ReconciliationList;
