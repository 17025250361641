import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import IndicatorBox from "../../../../../../containers/sustainability/multiStep/IndicatorBoxContainer";
import EditableTableContainer from "../../../../../../containers/sustainability/allocation/offRoad/EditableTable";
import MatchingTablesContainer from "../../../../../../containers/sustainability/allocation/offRoad/MatchingTables";
import OverviewPanel from "./OverviewPanel";
import OverviewTableContainer from "../../../../../../containers/sustainability/allocation/offRoad/OverviewTable";
import OverviewTableFulfillmentContainer from "../../../../../../containers/sustainability/multiStep/OverviewTableFulfillmentContainer";
import OverviewTableConstructionContainer from "../../../../../../containers/sustainability/multiStep/OverviewTableConstructionContainer";
import startAllocation from "../../../../../../api/sustainability/allocationSteps/startAllocation";
import {Typography} from "@material-ui/core";
import allocationConstruction from "../../../../../../api/sustainability/allocationSteps/allocationConstruction";
import { ReactReduxContext, useDispatch } from "react-redux";
import {
    postSetActiveStepOffroad,
    postStepBiotemplatesData,
    putStepOverViewData
} from "../../../../../../api/sustainability/allocation/offRoadUse/stepData";
import MatchingTables from "../../../../../../containers/sustainability/allocation/offRoad/MatchingTables";
import setAllocationConstruction from "../../../../../../api/sustainability/allocationSteps/setAllocationConstruction";
import setAllocateConstructionReports
    from "../../../../../../api/sustainability/allocationSteps/setAllocateConstructionReports";
import OffRoadCustomerRequirements from "./OffRoadCustomerRequirements";
import SuccessView from "./SuccessView";
import {
    updateBiotemplateStepFour,
    updateBiotemplateStepOne, updateBiotemplateStepThree,
    updateBiotemplateStepTwo
} from "../../../../../../reducers/sustainability/allocation/offRoadAllocation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {},
        btnContainer: {
            marginBottom: theme.spacing(3),
        },
        btn: {
            paddingTop: theme.spacing(0.8),
            paddingBottom: theme.spacing(0.8),
            minWidth: "110px",
            "&:first-child": {
                marginRight: theme.spacing(2),
            },
        },
        btnDark: {
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.common.white,
        },
        cardContent: {
            maxHeight: "calc(100vh - 250px)",
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(3),
            paddingBottom: 0,
        },
        overviewPanel: {
            backgroundColor: theme.palette.common.white,
            marginLeft: theme.spacing(-3),
            marginRight: theme.spacing(-3),
            marginBottom: theme.spacing(-3),
        },
    })
);

interface OffRoadCardEditableTableProps {
    step: number;
    handleNext: () => void;
    handleBack: () => void;
    updateIsFetching: (value: boolean) => void;
    availableVol: any;
    biotemplates: any[];
    regularBiotemplates: any[];
    advancedBiotemplates: any[];
}
interface Biotemplate {
    id: number;
    biofuel_type: string;
    raw_material_origin: string;
    fuel_type: string;
    raw_material: string;
    double_counted_norway: string;
    reduction_in_climate_emission: number;
    storage_terminal: string;
    available_quantity: number;
    allocated_quantity: number;
    allocated_eq_quantity: number;
}
interface UpdatedDataProps {
    biotemplates?: any[];
    overview?: any[];
    id?: number;
}
interface Requirement {
    construction_volume_requirement: any;
}
const OffRoadCardEditableTable: React.FC<OffRoadCardEditableTableProps> = ({
                                                                               step,
                                                                               handleBack,
                                                                               handleNext,
                                                                               updateIsFetching,
                                                                               availableVol,
                                                                               biotemplates,
                                                                               regularBiotemplates,
                                                                               advancedBiotemplates
                                                                           }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [overViewData, setOverViewData] = useState(null);
    const [updatedCustomerData, setUpdatedCustomerData] = useState([]);
    const [updatedData, setUpdatedData] = useState<any[]>([]);
    const [stepBiotemplates, setStepBiotemplates] = useState([]);
    const [requirementData, setRequirementData] = useState<Requirement>({
        construction_volume_requirement: {},
    });


    const handleTableDataUpdate = (updatedData: UpdatedDataProps, type: string) => {
        if (type === "bio") {
            console.log("Updating biotemplates:", updatedData.biotemplates);
            setUpdatedData(prevData => ({
                ...prevData,
                // @ts-ignore
                biotemplates: updatedData.biotemplates,
            }));
        } else {
            setUpdatedData(prevData => ({
                ...prevData,
                // @ts-ignore
                overview: updatedData.overview,
                id: updatedData.id,
            }));
        }

    };

    const handleCustomerDataUpdate = (updatedCustomerData: any[]) => {
        // @ts-ignore
        setUpdatedCustomerData(updatedCustomerData);
    };

    const handleRequirementDataUpdate = (requirementData: Requirement) => {
        // @ts-ignore
        setRequirementData(requirementData);
    };

    const clickBack = () => {
        handleBack();
    };

    const clickNext = async () => {

        let brk = 0
        if (step < 5) {
            const biotemplateUpdatedData = {
                // @ts-ignore
                biotemplates : updatedData.biotemplates,
            };

            biotemplateUpdatedData.biotemplates.forEach((item: { allocated_quantity: string; available_quantity: number; }) => {
                if(parseInt(item.allocated_quantity) > item.available_quantity ){
                    brk += 1
                }
            })
            if(brk){
                alert("One or more allocated batches have available volumes exceeded.")
                return
            }

            updateIsFetching(true);
            if (step == 0){

            }
            if (step == 1){
                dispatch(updateBiotemplateStepOne(biotemplateUpdatedData.biotemplates))
            }
            if (step == 2){
                dispatch(updateBiotemplateStepTwo(biotemplateUpdatedData.biotemplates))
            }
            if (step == 3){
                dispatch(updateBiotemplateStepThree(biotemplateUpdatedData.biotemplates))
            }
            if (step == 4){
                dispatch(updateBiotemplateStepFour(biotemplateUpdatedData.biotemplates))
            }
            const setActiveStep = await postSetActiveStepOffroad(step + 1)
            const response = await postStepBiotemplatesData(step, biotemplateUpdatedData);
            if (response && response.success) {
                if (step === 4) {
                    // @ts-ignore
                    const reqSetResponse = await setAllocationConstruction(
                        requirementData.construction_volume_requirement
                    );
                    if (reqSetResponse && reqSetResponse.valid) {
                        handleNext();
                    } else {
                        alert("Some error occurred");
                    }
                } else {
                    // @ts-ignore
                    const overviewData = { ...updatedData.overview };
                    // @ts-ignore
                    overviewData.id = updatedData.id;

                    const putResponse = await putStepOverViewData(step, overviewData);
                    handleNext();
                }
                updateIsFetching(false);
            } else {
                updateIsFetching(false);
                alert("Some error occurred");
            }
        } else if (step === 6) {
            // console.error("updatedCustomerData");
            // console.error(updatedCustomerData);
            updateIsFetching(true);
            const getCustomerReqAllocated = () => {
                // @ts-ignore
                const allocated_sorted = updatedCustomerData.allocated_sorted;
                let allocated: any = {};
                if (allocated_sorted && Object.keys(allocated_sorted).length > 0) {
                    Object.keys(allocated_sorted).map((cust_id, cust_idx) => {
                        Object.keys(allocated_sorted[cust_id]).map((an_id, an_idx) => {
                            allocated = {
                                ...allocated,
                                [an_id]: {
                                    ...allocated_sorted[cust_id][an_id],
                                },
                            };
                        });
                    });
                }
                return allocated;
            };
            const apiAllocated = getCustomerReqAllocated();

            try {
                const response = await setAllocateConstructionReports(apiAllocated);
                if (response.valid) {
                    updateIsFetching(false);
                    handleNext();
                } else {
                    updateIsFetching(false);
                    alert("Some error occured when submitting data.");
                }
            } catch (error) {
                updateIsFetching(false);
                alert("Some error occured when submitting data.");
            }
        } else {
            handleNext();
        }
    };

    useEffect(() => {
        if (step === 4) {
            // @ts-ignore
            setStepBiotemplates(regularBiotemplates);
        } else {
            // @ts-ignore
            setStepBiotemplates(advancedBiotemplates);
        }
    }, [step, regularBiotemplates, advancedBiotemplates]);
    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    className={classes.btnContainer}
                >
                    <Grid item>
                    </Grid>
                    <Grid item>
                        {step > 0 && (
                            <ButtonRounded
                                variant="contained"
                                className={clsx(classes.btn, classes.btnDark)}
                                onClick={clickBack}
                            >
                                Back
                            </ButtonRounded>
                        )}
                        <ButtonRounded
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                            onClick={clickNext}
                        >
                            Next
                        </ButtonRounded>
                    </Grid>
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                >
                    <Grid item>
                        {step === 1 && (
                            <>
                                <Typography variant="h4">Allocation of the marine mandate (10%)</Typography>
                                <Typography variant="body2" >
                                    In this step you will allocate the sustainability criteria for the required volumes to fulfill the general off-road mandate. NB! The off-road mandate only accepts biofuels with advanced sustainablity criteria. If additional volumes are supplied to the market, this will be allocated in step 4 (all other volumes).
                                </Typography>
                                <br /><br />
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <Typography variant="h4">Allocation of volumes required by customers to be above the mandate</Typography>
                                <Typography variant="body2" >
                                    In this step you will allocate the sustainability criteria for the volumes that customers have requested to be above the mandate levels. If there are additional volumes supplied to the market that is a surplus to the mandate levels, this will be allocated in step 4.
                                </Typography>
                                <br /><br />
                            </>
                        )}
                        {step === 4 && (
                            <>
                                <Typography variant="h4">Allocation of volumes not applicable to the mandate</Typography>
                                <Typography variant="body2" >
                                    In this step you will allocate the sustainability criteria for potential sold bio volumes that are not applicable to the mandate. This will in practice be all dyed biofuel volumes with raw materials not qualifying to be advanced.
                                </Typography>
                                <br /><br />
                            </>
                        )}
                    </Grid>
                </Grid>
                {step === 5 && (
                    <MatchingTables/>
                )}
                {step === 6 && (
                    <OffRoadCustomerRequirements
                        onUpdateCustomerData={handleCustomerDataUpdate}
                    />
                )}
                {step === 7 && (
                    <SuccessView text={"Allocation process is successful"} />
                )}
                {step !== 5 && step !== 6 && step !== 7 && (
                    <EditableTableContainer data={stepBiotemplates}
                                            updateIsFetching={updateIsFetching}
                                            onUpdateData={handleTableDataUpdate}
                                            onUpdateRequirement={handleRequirementDataUpdate}
                                            activeStep={step} />
                )}
            </CardContent>
        </Card>
    );
};

export default OffRoadCardEditableTable;
