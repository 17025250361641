
// State interface
export interface MarineAllocationState{
    biotemplatesInAllocationStepZero: [],
    biotemplatesInAllocationStepOne: [],
    biotemplatesInAllocationStepTwo: [],
    biotemplatesInAllocationStepThree: [],
    biotemplatesInAllocationStepFour: [],
}
//initialState
const initialState = {
    biotemplatesInAllocationStepZero: [],
    biotemplatesInAllocationStepOne: [],
    biotemplatesInAllocationStepTwo: [],
    biotemplatesInAllocationStepThree: [],
    biotemplatesInAllocationStepFour: [],
} as MarineAllocationState

//Action
const UPDATE_BIOTEMPLATES_STEP_ZERO = 'UPDATE_BIOTEMPLATES_STEP_ZERO'
const UPDATE_BIOTEMPLATES_STEP_ONE = 'UPDATE_BIOTEMPLATES_STEP_ONE'
const UPDATE_BIOTEMPLATES_STEP_TWO = 'UPDATE_BIOTEMPLATES_STEP_TWO'
const UPDATE_BIOTEMPLATES_STEP_THREE = 'UPDATE_BIOTEMPLATES_STEP_THREE'
const UPDATE_BIOTEMPLATES_STEP_FOUR = 'UPDATE_BIOTEMPLATES_STEP_FOUR'
const EMPTY_KEY = 'EMPTY_KEY'
//Action creator
export const updateBiotemplateStepZero = (data:any) => {
    return {
        type: UPDATE_BIOTEMPLATES_STEP_ZERO,
        data: data
    }
}
export const updateBiotemplateStepOne = (data:any) => {
    return {
        type: UPDATE_BIOTEMPLATES_STEP_ONE,
        data: data
    }
}
export const updateBiotemplateStepTwo = (data:any) => {
    return {
        type: UPDATE_BIOTEMPLATES_STEP_TWO,
        data: data
    }
}
export const updateBiotemplateStepThree = (data:any) => {
    return {
        type: UPDATE_BIOTEMPLATES_STEP_THREE,
        data: data
    }
}
export const updateBiotemplateStepFour = (data:any) => {
    return {
        type: UPDATE_BIOTEMPLATES_STEP_FOUR,
        data: data
    }
}

export const emptyKey = () => {
    return {
        type: EMPTY_KEY,
        data:[]
    }
}
//Reducer
const MarineAllocationReducer = (state = initialState, action:any) =>{
    switch (action.type){
        case UPDATE_BIOTEMPLATES_STEP_ZERO: return {
            ...state,
            biotemplatesInAllocationStepZero: {...action.data}
        }
        case UPDATE_BIOTEMPLATES_STEP_ONE: return {
            ...state,
            biotemplatesInAllocationStepOne: {...action.data}
        }
        case UPDATE_BIOTEMPLATES_STEP_TWO: return {
            ...state,
            biotemplatesInAllocationStepTwo: {...action.data}
        }
        case UPDATE_BIOTEMPLATES_STEP_THREE: return {
            ...state,
            biotemplatesInAllocationStepThree: {...action.data}
        }
        case UPDATE_BIOTEMPLATES_STEP_FOUR: return {
            ...state,
            biotemplatesInAllocationStepFour: {...action.data}
        }

        case EMPTY_KEY: return{
            biotemplatesInAllocationStepZero: [],
            biotemplatesInAllocationStepOne: [],
            biotemplatesInAllocationStepTwo: [],
            biotemplatesInAllocationStepThree: [],
            biotemplatesInAllocationStepFour: [],
        }

        default: return state
    }
}

export default MarineAllocationReducer
